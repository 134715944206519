import React from 'react';
import MyInfo from '../components/EmployeeProfile/myinfo'; // Assuming the correct path

const MyInfoPage = () => {
  return (
    <div>
      <MyInfo />
    </div>
  );
}

export default MyInfoPage;
