import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Space,
  Select,
  Collapse,
  Tag,
  Typography,
  List,
  Avatar,
  Rate,
  message,
  DatePicker,
  Modal,
} from "antd";
import {
  SendOutlined,
  EditOutlined,
  UpOutlined,
  DeleteOutlined,
  SaveOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import axios from "axios";
import moment from "moment-timezone";
import authHeader from "../../services/auth-header";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const { Option } = Select;
const { Panel } = Collapse;
const { Text } = Typography;

const YourGoals = ({ employeeId, refreshGoals }) => {
  const [submittedGoals, setSubmittedGoals] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [editingComment, setEditingComment] = useState(null);
  const [activeKeys, setActiveKeys] = useState([]);
  const { user } = useAuth();
  const [showAllComments, setShowAllComments] = useState({});
  const [originalGoal, setOriginalGoal] = useState(null);
  const navigate = useNavigate();
  const { confirm } = Modal;

  const [editingGoalId, setEditingGoalId] = useState(null);
  const [editedGoal, setEditedGoal] = useState({});

  useEffect(() => {
    if (employeeId) {
      fetchSubmittedGoals();
    }
  }, [employeeId, refreshGoals]);

  const handleEditClick = (goal) => {
    if (!goal) {
      message.error("Invalid goal data.");
      return;
    }
  
    setEditingGoalId(goal.id);
    setEditedGoal({
      name: goal.name,
      description: goal.description,
      targetDate: moment(goal.targetDate),
      goalCategory: goal.goalCategory,
      goalUrl: goal.goalUrl
    });
    setOriginalGoal(goal);
  };
  


  const formatCommentTime = (utcTime) => {
    return moment.utc(utcTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss z");
  };

  const handleApiError = (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/login");
    } else {
      message.error("Failed to fetch data");
    }
  };

  const fetchComments = async (goalId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/goals/${goalId}/comments`,
        {
          headers: authHeader(),
        }
      );
      setSubmittedGoals((prevGoals) =>
        prevGoals.map((goal) =>
          goal.id === goalId ? { ...goal, comments: response.data } : goal
        )
      );
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchSubmittedGoals = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/employees/${employeeId}/goals`,
        {
          headers: authHeader(),
        }
      );

      const goalsWithComments = await Promise.all(
        response.data.map(async (goal) => {
          try {
            const commentsResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/goals/${goal.id}/comments`,
              {
                headers: authHeader(),
              }
            );

            const managerId = parseInt(user.user_id);
            const goalEmployeeId = parseInt(goal.employeeID);

            if (isNaN(managerId) || isNaN(goalEmployeeId)) {
              console.error("Invalid managerId or employeeId", {
                managerId,
                goalEmployeeId,
              });
              return { ...goal, isCurrentUserManager: false };
            }

            const managerCheckResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/employees/checkManager/${managerId}/${goalEmployeeId}`,
              {
                headers: authHeader(),
              }
            );

            return {
              ...goal,
              comments: commentsResponse.data,
              employeeRating: goal.employeeGoalRating,
              managerRating: goal.goalCompletionStatus,
              isCurrentUserManager: managerCheckResponse.data.isManager,
            };
          } catch (error) {
            handleApiError(error);
            console.error(
              "Error processing goal:",
              goal,
              error.response?.data || error.message
            );
            return { ...goal, comments: [], isCurrentUserManager: false };
          }
        })
      );
      setSubmittedGoals(goalsWithComments);
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleCollapseChange = (keys) => {
    setActiveKeys(keys);
    keys.forEach((key) => {
      if (!activeKeys.includes(key)) {
        fetchComments(key);
      }
    });
  };

  const handleAddComment = (goalId) => {
    if (!newComment.trim()) {
      message.warning("Cannot send an empty comment");
      return;
    }

    const updatedBy = user.user_name;
    const currentTime = moment().toISOString();

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/goal/comment`,
        {
          goalID: goalId,
          comments: newComment,
          updatedBy,
          updatedAt: currentTime,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        fetchSubmittedGoals();
        setNewComment("");
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleUpdateGoalStatus = (id, newStatus) => {
    setSubmittedGoals(
      submittedGoals.map((goal) =>
        goal.id === id ? { ...goal, status: newStatus } : goal
      )
    );

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/goal/status/${id}`,
        { status: newStatus },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {})
      .catch((error) => {
        handleApiError(error);
      });
  };

  const handleEditComment = (goalId, commentId, newText) => {
    const currentTime = moment().toISOString();

    axios
      .put(
        `${process.env.REACT_APP_API_URL}/goal/comment/${commentId}`,
        { comments: newText, updatedAt: currentTime },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        fetchSubmittedGoals();
        setEditingComment(null);
      })
      .catch((error) => {
        handleApiError(error);
      });
  };

  const toggleShowAllComments = (goalId) => {
    setShowAllComments((prev) => ({ ...prev, [goalId]: !prev[goalId] }));
  };

  const handleEditGoal = (goal) => {
    setEditingGoalId(goal.id);
    setEditedGoal({
      name: goal.name,
      description: goal.description,
      targetDate: moment(goal.targetDate),
      goalCategory: goal.goalCategory,
      goalUrl: goal.goalUrl,
    });
  };

  const handleCancelEdit = () => {
    setEditingGoalId(null);
    setEditedGoal({});
  };

  const handleUpdateGoalFields = async () => {
    if (!editingGoalId) return;
  
    if (!originalGoal) {
      message.error("Original goal data is not available.");
      return;
    }
  
    if (originalGoal.status !== "Draft") {
      message.error("Only goals in Draft status can be updated.");
      return;
    }
  
    // Ensure that the date is formatted only when it exists
    const formattedTargetDate = editedGoal.targetDate
      ? moment(editedGoal.targetDate).format("YYYY-MM-DD")
      : null;
  
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/update/goal/${editingGoalId}`,
        {
          ...editedGoal,
          targetDate: formattedTargetDate, // Send the formatted date
        },
        {
          headers: authHeader(),
        }
      );
  
      if (response.status === 200) {
        message.success(response.data.message);
      } else {
        message.error(response.data.message || "Failed to update goal");
      }
  
      setEditingGoalId(null);
      setEditedGoal({});
      setOriginalGoal(null);
      fetchSubmittedGoals();
    } catch (error) {
      handleApiError(error);
    }
  };
  
  
  const handleUpdateGoal = (goalId, field, { value }) => {
    const updatedGoal = submittedGoals.find((goal) => goal.id === goalId);
  
    if (!updatedGoal) return;
  
    const updatedFields = {
      ...updatedGoal,
      [field]: ratingLabels[value],
    };
  
    setSubmittedGoals((prevGoals) =>
      prevGoals.map((goal) =>
        goal.id === goalId ? { ...goal, ...updatedFields } : goal
      )
    );
  
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/goals/${goalId}/fields`,
        updatedFields,
        { headers: authHeader() }
      )
      .then(() => {
        message.success("Goal updated successfully");
        fetchSubmittedGoals();
      })
      .catch((error) => {
        handleApiError(error);
      });
  };
  

  const handleInputChange = (field, value) => {
    setEditedGoal((prev) => ({ ...prev, [field]: value }));
  };

  const handleDeleteGoal = async (goalId) => {
    const goalToDelete = submittedGoals.find(goal => goal.id === goalId);
    
    if (!goalToDelete) {
      message.error("Goal not found.");
      return;
    }
  
    if (goalToDelete.status !== "Draft") {
      message.warning("Only goals in Draft status can be deleted.");
      return;
    }
  
    // Show confirmation dialog
    confirm({
      title: 'Are you sure you want to delete this goal?',
      content: 'Once deleted, you will not be able to recover this goal.',
      okText: 'Yes',
      cancelText: 'No',
      onOk: async () => {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/goals/${goalId}`, {
            headers: authHeader(),
          });
          message.success("Goal deleted successfully");
          fetchSubmittedGoals(); // Refresh the goal list
        } catch (error) {
          handleApiError(error);
        }
      },
      onCancel() {
        message.info('Goal deletion cancelled.');
      },
    });
  };


  const ratingLabels = [
    "Unsatisfactory",
    "Needs Improvement",
    "Meets Expectations",
    "Exceeds Expectations",
    "Outstanding",
  ];

  const categoryLabels = {
    Expertise: "#a83f2b",
    Ownership: "#b4b53b",
    Leadership: "#2a3a60",
  };

  const renderComments = (goal) => {
    const allComments = goal.comments;
    const commentsToShow = showAllComments[goal.id]
      ? allComments
      : allComments.slice(-4);

    return (
      <>
        {goal.comments.length > 4 && !showAllComments[goal.id] && (
          <Button onClick={() => toggleShowAllComments(goal.id)}>
            View Previous Comments <UpOutlined />
          </Button>
        )}
        <List
          itemLayout="horizontal"
          dataSource={commentsToShow}
          renderItem={(comment, index) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar>{comment.updatedBy[0]}</Avatar>}
                title={
                  <Text>
                    {comment.updatedBy}{" "}
                    <Text type="secondary">
                      {formatCommentTime(comment.updatedAt)}
                    </Text>
                  </Text>
                }
                description={
                  editingComment === comment.id &&
                  index === commentsToShow.length - 1 &&
                  comment.updatedBy === user.user_name ? (
                    <Input.TextArea
                      defaultValue={comment.comments}
                      onPressEnter={(e) =>
                        handleEditComment(goal.id, comment.id, e.target.value)
                      }
                      onBlur={() => setEditingComment(null)}
                      autoFocus
                    />
                  ) : (
                    <Text>{comment.comments}</Text>
                  )
                }
              />
              {index === commentsToShow.length - 1 &&
                editingComment !== comment.id &&
                comment.updatedBy === user.user_name && (
                  <Button
                    icon={<EditOutlined />}
                    onClick={() => setEditingComment(comment.id)}
                  />
                )}
            </List.Item>
          )}
        />
        {goal.comments.length > 4 && showAllComments[goal.id] && (
          <Button onClick={() => toggleShowAllComments(goal.id)}>
            Show Less <UpOutlined />
          </Button>
        )}
      </>
    );
  };

  const renderGoalContent = (goal) => {
    const isEditing = goal.id === editingGoalId;

    if (isEditing) {
      return (
        <div style={{ padding: 15 }}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              value={editedGoal.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              placeholder="Goal Name"
            />
            <Input.TextArea
              value={editedGoal.description}
              onChange={(e) => handleInputChange("description", e.target.value)}
              placeholder="Description"
            />
            <DatePicker
            value={editedGoal.targetDate ? moment(editedGoal.targetDate) : null}
            onChange={(date) => handleInputChange('targetDate', date ? date.format('YYYY-MM-DD') : null)}
             />
            <Select
              value={editedGoal.goalCategory}
              onChange={(value) => handleInputChange("goalCategory", value)}
              style={{ width: "100%" }}
            >
              <Option value="Expertise">Expertise</Option>
              <Option value="Ownership">Ownership</Option>
              <Option value="Leadership">Leadership</Option>
            </Select>
            <Input
              value={editedGoal.goalUrl}
              onChange={(e) => handleInputChange("goalUrl", e.target.value)}
              placeholder="Goal URL"
            />
            <Space>
              <Button
                type="primary"
                onClick={handleUpdateGoalFields}
                disabled={!editingGoalId || goal.status !== "Draft"}
              >
                Save Changes
              </Button>
              <Button onClick={handleCancelEdit} icon={<CloseOutlined />}>
                Cancel
              </Button>
            </Space>
          </Space>
        </div>
      );
    }

    return (
      <div style={{ padding: 15 }}>
        <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 10 }}>
          <div style={{ flex: 1, marginRight: 10, minWidth: "200px" }}>
            <span style={{ fontWeight: "bold" }}>Goal Name:</span> {goal.name}
          </div>
          <div style={{ flex: 1, marginRight: 10, minWidth: "200px" }}>
            <span style={{ fontWeight: "bold" }}>Category:</span>{" "}
            {goal.goalCategory}
          </div>
          <div style={{ flex: 1, marginRight: 10, minWidth: "200px" }}>
            <span style={{ fontWeight: "bold" }}>Target Date:</span>{" "}
            {goal.targetDate}
          </div>
          <div
            style={{
              flex: 1,
              marginRight: 10,
              minWidth: "200px",
              wordWrap: "break-word",
            }}
          >
            <span style={{ fontWeight: "bold" }}>URL:</span>
            <a
              href={goal.goalUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "blue" }}
            >
              {goal.goalUrl}
            </a>
          </div>
        </div>
        <div style={{ marginBottom: 10 }}>
          <span style={{ fontWeight: "bold", marginRight: 10 }}>
            Description:
          </span>{" "}
          {goal.description}
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 10,
            }}
          >
            <div style={{ flex: 1, marginRight: 10, minWidth: "200px" }}>
              <span style={{ fontWeight: "bold" }}>Employee Rating:</span>
              <Rate
                value={ratingLabels.indexOf(goal.employeeRating)}
                onChange={(value) =>
                  handleUpdateGoal(goal.id, "employeeRating", { value })
                }
                tooltips={ratingLabels}
                disabled={user.employeeId !== goal.employeeID}
              />
            </div>
            <div style={{ flex: 1, marginRight: 10, minWidth: "200px" }}>
              <span style={{ fontWeight: "bold" }}>Manager Rating:</span>
              <Rate
                value={ratingLabels.indexOf(goal.managerRating)}
                onChange={(value) =>
                  handleUpdateGoal(goal.id, "managerRating", { value })
                }
                tooltips={ratingLabels}
                disabled={!goal.isCurrentUserManager}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: 10 }}>
          {goal.status === "Draft" && (
            <>
              <Button
                onClick={() => handleEditClick(goal)}
                icon={<EditOutlined />}
                style={{ marginRight: 10 }}
              >
                Edit
              </Button>
              <Button
                danger
                onClick={() => handleDeleteGoal(goal.id)}
                icon={<DeleteOutlined />}
              >
                Delete
              </Button>
            </>
          )}
          {goal.status === "Draft" && (
            <Button
              onClick={() => handleUpdateGoalStatus(goal.id, "Submitted")}
              style={{ marginLeft: 10 }}
            >
              Submit
            </Button>
          )}
          {goal.status === "In Progress" && (
            <Button
              onClick={() => handleUpdateGoalStatus(goal.id, "Completed")}
            >
              Mark as Complete
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          marginTop: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Object.entries(categoryLabels).map(([category, color], index) => (
          <React.Fragment key={category}>
            <Tag
              color={color}
              style={{ marginRight: 10, color: "#fff", fontWeight: "bold" }}
            >
              {category}
            </Tag>
            {index < Object.keys(categoryLabels).length - 1 && (
              <span style={{ marginRight: 10, fontWeight: "bold" }}></span>
            )}
          </React.Fragment>
        ))}
      </div>

      <h2>Your Goals</h2>
      <Collapse activeKey={activeKeys} onChange={handleCollapseChange}>
        {submittedGoals.map((goal) => (
          <Panel
            key={goal.id}
            header={
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{goal.name}</span>
                <span style={{ fontWeight: "bold" }}>{goal.status}</span>
              </div>
            }
            style={{
              background: `${categoryLabels[goal.goalCategory]}22`,
              marginBottom: 5,
              borderRadius: 3,
              padding: 1,
              borderLeft: `5px solid ${categoryLabels[goal.goalCategory]}`,
            }}
          >
            {renderGoalContent(goal)}
            <h3>Comments</h3>
            {goal.comments && goal.comments.length > 0 ? (
              renderComments(goal)
            ) : (
              <p>No comments available</p>
            )}
            <Input
              placeholder="Add a comment"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onPressEnter={() => {
                if (newComment.trim()) {
                  handleAddComment(goal.id);
                } else {
                  message.error("Cannot send an empty comment");
                }
              }}
              suffix={
                <Button
                  type="primary"
                  icon={<SendOutlined />}
                  onClick={() => handleAddComment(goal.id)}
                  disabled={!newComment.trim()}
                />
              }
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default YourGoals;