// src/context/AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  // In your AuthContext provider
  const [user, setUser] = useState(() => {
    // Try to get a saved user from localStorage or start with null
    const savedUser = JSON.parse(localStorage.getItem('user'));
    return savedUser || null;
  });

  const [userStatus, setUserStatus] = useState(localStorage.getItem('jwtToken') ? 'authenticated' : 'unauthenticated');



  return (
    <AuthContext.Provider value={{ user, setUser,userStatus, setUserStatus}}>
      {children}
    </AuthContext.Provider>
  );
};


export const useAuth = () => useContext(AuthContext);
