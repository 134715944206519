// import React, { useState, useEffect } from 'react';
// import { Table, Modal, Rate, Button, Input, List, Avatar, message } from 'antd';
// import { SendOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import moment from 'moment-timezone';
// import authHeader from '../../services/auth-header';

// const { TextArea } = Input;

// const EmployeeGoalsView = ({ employeeId }) => {
//   const [goals, setGoals] = useState([]);
//   const [selectedGoal, setSelectedGoal] = useState(null);
//   const [modalVisible, setModalVisible] = useState(false);
//   const [newComment, setNewComment] = useState('');

//   useEffect(() => {
//     if (employeeId) {
//       fetchEmployeeGoals();
//     }
//   }, [employeeId]);

//   const fetchEmployeeGoals = async () => {
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/goals`, {
//         headers: authHeader(),
//       });
//       const goalsWithComments = await Promise.all(response.data.map(async (goal) => {
//         const commentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/goals/${goal.id}/comments`, {
//           headers: authHeader(),
//         });
//         return { ...goal, comments: commentsResponse.data };
//       }));
//       setGoals(goalsWithComments);
//     } catch (error) {
//       message.error('Failed to fetch employee goals');
//     }
//   };

//   const handleStatusUpdate = async (goalId, newStatus) => {
//     try {
//       await axios.put(`${process.env.REACT_APP_API_URL}/goal/status/${goalId}`, { status: newStatus }, {
//         headers: authHeader()
//       });
//       fetchEmployeeGoals();
//       message.success(`Goal status updated to ${newStatus}`);
//     } catch (error) {
//       message.error('Failed to update goal status');
//     }
//   };

//   const handleManagerRatingUpdate = async (goalId, rating) => {
//     try {
//       await axios.put(`${process.env.REACT_APP_API_URL}/goals/${goalId}/manager-rating`, { managerRating: rating }, {
//         headers: authHeader()
//       });
//       fetchEmployeeGoals();
//       message.success('Manager rating updated');
//     } catch (error) {
//       message.error('Failed to update manager rating');
//     }
//   };

//   const handleAddComment = async (goalId) => {
//     try {
//       const currentTime = moment().toISOString();
//       await axios.post(`${process.env.REACT_APP_API_URL}/goal/comment`, {
//         goalID: goalId,
//         comments: newComment,
//         updatedAt: currentTime
//       }, {
//         headers: authHeader()
//       });
//       setNewComment('');
//       fetchEmployeeGoals();
//       message.success('Comment added successfully');
//     } catch (error) {
//       message.error('Failed to add comment');
//     }
//   };

//   const columns = [
//     { title: 'Goal Name', dataIndex: 'name', key: 'name' },
//     { title: 'Category', dataIndex: 'goalCategory', key: 'category' },
//     { title: 'Status', dataIndex: 'status', key: 'status' },
//     { 
//       title: 'Actions', 
//       key: 'actions',
//       render: (text, record) => (
//         <span>
//           {record.status === 'Submitted' && (
//             <Button onClick={() => handleStatusUpdate(record.id, 'In Progress')}>Accept</Button>
//           )}
//           {record.status === 'Completed' && (
//             <>
//               <Button onClick={() => handleStatusUpdate(record.id, 'Approved')}>Approve</Button>
//               <Button onClick={() => handleStatusUpdate(record.id, 'Cancelled')}>Reject</Button>
//             </>
//           )}
//           <Button onClick={() => {
//             setSelectedGoal(record);
//             setModalVisible(true);
//           }}>
//             View Details
//           </Button>
//         </span>
//       ),
//     },
//   ];

//   const ratingLabels = ['Unsatisfactory', 'Needs Improvement', 'Meets Expectations', 'Exceeds Expectations', 'Outstanding'];

//   return (
//     <div>
//       <Table dataSource={goals} columns={columns} rowKey="id" />
//       <Modal
//         title={selectedGoal ? selectedGoal.name : ''}
//         visible={modalVisible}
//         onCancel={() => setModalVisible(false)}
//         footer={[
//           <Button key="close" onClick={() => setModalVisible(false)}>Close</Button>,
//         ]}
//       >
//         {selectedGoal && (
//           <>
//             <p><strong>Description:</strong> {selectedGoal.description}</p>
//             <p><strong>Category:</strong> {selectedGoal.goalCategory}</p>
//             <p><strong>Status:</strong> {selectedGoal.status}</p>
//             <p><strong>Due Date:</strong> {selectedGoal.dueDate}</p>
//             <p><strong>Manager Rating:</strong></p>
//             <Rate
//               tooltips={ratingLabels}
//               value={selectedGoal.managerRating}
//               onChange={(value) => handleManagerRatingUpdate(selectedGoal.id, value)}
//             />
//             <TextArea
//               rows={4}
//               placeholder="Add a comment"
//               value={newComment}
//               onChange={e => setNewComment(e.target.value)}
//             />
//             <Button onClick={() => handleAddComment(selectedGoal.id)}>Add Comment</Button>
//             <List
//               header={`${selectedGoal.comments.length} comments`}
//               itemLayout="horizontal"
//               dataSource={selectedGoal.comments}
//               renderItem={comment => (
//                 <List.Item>
//                   <List.Item.Meta
//                     avatar={<Avatar icon={<SendOutlined />} />}
//                     title={comment.username}
//                     description={comment.comments}
//                   />
//                 </List.Item>
//               )}
//             />
//           </>
//         )}
//       </Modal>
//     </div>
//   );
// };

// export default EmployeeGoalsView;



import React, { useState, useEffect } from 'react';
import { Table, Modal, Rate, Button, Input, List, Avatar, message } from 'antd';
import axios from 'axios';
import moment from 'moment-timezone';
import authHeader from '../../services/auth-header';
import { useAuth } from '../../context/AuthContext';

const { TextArea } = Input;

const EmployeeGoalsView = ({ employeeId }) => {
  const [goals, setGoals] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [newComment, setNewComment] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (employeeId) {
      fetchEmployeeGoals();
    }
  }, [employeeId]);

  const fetchEmployeeGoals = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/employees/${employeeId}/goals`, {
        headers: authHeader(),
      });
      const goalsWithComments = await Promise.all(response.data.map(async (goal) => {
        const commentsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/goals/${goal.id}/comments`, {
          headers: authHeader(),
        });
        return { ...goal, comments: commentsResponse.data || [] };
      }));
      setGoals(goalsWithComments);
    } catch (error) {
      message.error('Failed to fetch employee goals');
    }
  };

  const handleStatusUpdate = async (goalId, newStatus) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/goal/status/${goalId}`, { status: newStatus }, {
        headers: authHeader()
      });
      fetchEmployeeGoals();
      message.success(`Goal status updated to ${newStatus}`);
    } catch (error) {
      message.error('Failed to update goal status');
    }
  };

  const handleManagerRatingUpdate = async (goalId, rating) => {
    try {
      const ratingLabel = ratingLabels[rating];
      await axios.put(`${process.env.REACT_APP_API_URL}/goals/${goalId}/manager-rating`, { managerRating: ratingLabel }, {
        headers: authHeader()
      });
      fetchEmployeeGoals();
      message.success('Manager rating updated');
    } catch (error) {
      message.error('Failed to update manager rating');
    }
  };

  const handleAddComment = async (goalId) => {
    try {
      const currentTime = moment().toISOString();
      await axios.post(`${process.env.REACT_APP_API_URL}/goal/comment`, {
        goalID: goalId,
        comments: newComment,
        updatedBy: user.user_name, // Replace with the actual current user
        updatedAt: currentTime
      }, {
        headers: authHeader()
      });
      setNewComment('');
      fetchEmployeeGoals();
      message.success('Comment added successfully');
    } catch (error) {
      message.error('Failed to add comment');
    }
  };

  const columns = [
    { title: 'Goal Name', dataIndex: 'name', key: 'name' },
    { title: 'Category', dataIndex: 'goalCategory', key: 'category' },
    { title: 'Status', dataIndex: 'status', key: 'status' },
    { 
      title: 'Actions', 
      key: 'actions',
      render: (text, record) => (
        <span>
          {record.status === 'Submitted' && (
            <Button onClick={() => handleStatusUpdate(record.id, 'In Progress')}>Accept</Button>
          )}
          {record.status === 'Completed' && (
            <>
              <Button onClick={() => handleStatusUpdate(record.id, 'Approved')}>Approve</Button>
              <Button onClick={() => handleStatusUpdate(record.id, 'Cancelled')}>Reject</Button>
            </>
          )}
          <Button onClick={() => {
            setSelectedGoal(record);
            setModalVisible(true);
          }}>
            View Details
          </Button>
        </span>
      ),
    },
  ];

  const ratingLabels = ['Unsatisfactory', 'Needs Improvement', 'Meets Expectations', 'Exceeds Expectations', 'Outstanding'];

  return (
    <div>
      <Table dataSource={goals} columns={columns} rowKey="id" />
      <Modal
        title={selectedGoal ? selectedGoal.name : ''}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="close" onClick={() => setModalVisible(false)}>Close</Button>,
        ]}
      >
        {selectedGoal && (
          <>
            <p><strong>Description:</strong> {selectedGoal.description}</p>
            <p><strong>Category:</strong> {selectedGoal.goalCategory}</p>
            <p><strong>Status:</strong> {selectedGoal.status}</p>
            <p><strong>Due Date:</strong> {selectedGoal.dueDate}</p>
            <p><strong>Manager Rating:</strong></p>
            <Rate
              tooltips={ratingLabels}
              value={ratingLabels.indexOf(selectedGoal.managerRating)}
              onChange={(value) => handleManagerRatingUpdate(selectedGoal.id, value)}
            />
            <TextArea
              rows={4}
              placeholder="Add a comment"
              value={newComment}
              onChange={e => setNewComment(e.target.value)}
            />
            <Button onClick={() => handleAddComment(selectedGoal.id)}>Add Comment</Button>
            <List
              header={`${selectedGoal.comments.length} comments`}
              itemLayout="horizontal"
              dataSource={selectedGoal.comments}
              renderItem={comment => (
                comment ? (
                  <List.Item>
                    <List.Item.Meta
                      title={` ${comment.updatedBy}`}
                      description={comment.comments}
                    />
                  </List.Item>
                ) : null
              )}
            />
          </>
        )}
      </Modal>
    </div>
  );
};

export default EmployeeGoalsView;
