import React, { useState, useEffect } from 'react';
import { Table, Drawer, Button, Input } from 'antd';
import authHeader from '../../services/auth-header';
import EmployeeGoalsView from './EmployeeGoalsView';
import { useAuth } from '../../context/AuthContext';

const ManagerView = () => {
  const [employees, setEmployees] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [searchName, setSearchName] = useState('');
  const { user } = useAuth();
  const manager_id = user.user_id;

  useEffect(() => {
    const fetchData = async () => {
      try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/manager-employee-goals/${manager_id}`, {
          headers: authHeader()
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        if (data.success) {
          const processedEmployees = processEmployeeData(data.data);
          setEmployees(processedEmployees);
        } else {
          console.error('API request was not successful');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const processEmployeeData = (data) => {
    const employeeMap = new Map();

    data.employees.forEach(employee => {
      employeeMap.set(employee.id, { ...employee, goals: [] });
    });

    data.goals.forEach(goal => {
      const employee = employeeMap.get(goal.employeeID);
      if (employee) {
        employee.goals.push(goal);
      }
    });

    return Array.from(employeeMap.values());
  };

  const renderCompletedOutOfTotal = (goals, category) => {
    if (!goals || !Array.isArray(goals)) {
      return '0/0'; // or return a dash '-' if you prefer
    }
    const filteredGoals = goals.filter(goal => goal.goalCategory === category);
    const total = filteredGoals.length;
    const completed = filteredGoals.filter(goal => goal.status === 'Completed').length;
    return `${completed}/${total}`;
  };

  const renderGoalsByStatus = (goals, status) => {
    if (!goals || !Array.isArray(goals)) {
      return 0;
    }
    return goals.filter(goal => goal.status === status).length;
  };

  const showEmployeeGoals = (employee) => {
    setSelectedEmployee(employee);
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    setSelectedEmployee(null);
  };

  const handleSearch = (e) => {
    setSearchName(e.target.value);
  };

  const filteredEmployees = employees.filter(employee =>
    employee.fullName.toLowerCase().includes(searchName.toLowerCase())
  );

  const columns = [
    {
      title: 'Employee ID',
      dataIndex: 'employeeId',
      key: 'employeeId',
    },
    {
      title: 'Employee Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => (
        <Button type="link" onClick={() => showEmployeeGoals(record)}>{text}</Button>
      ),
    },
    {
      title: 'Expertise Goals (Completed/Total)',
      key: 'expertiseGoals',
      render: (text, record) => renderCompletedOutOfTotal(record.goals || [], 'Expertise'),
    },
    {
      title: 'Ownership Goals (Completed/Total)',
      key: 'ownershipGoals',
      render: (text, record) => renderCompletedOutOfTotal(record.goals || [], 'Ownership'),
    },
    {
      title: 'Leadership Goals (Completed/Total)',
      key: 'leadershipGoals',
      render: (text, record) => renderCompletedOutOfTotal(record.goals || [], 'Leadership'),
    },
    {
      title: 'Submitted Goals',
      key: 'submitted',
      render: (text, record) => renderGoalsByStatus(record.goals || [], 'Submitted'),
    },
    {
      title: 'Completed Goals',
      key: 'completed',
      render: (text, record) => renderGoalsByStatus(record.goals || [], 'Completed'),
    },
    {
      title: 'Approved Goals',
      key: 'approved',
      render: (text, record) => renderGoalsByStatus(record.goals || [], 'Approved'),
    },
  ];

  return (
    <div style={{ padding: 20 }}>
      <h1>Manager View</h1>
      <Input.Search
        placeholder="Search employee name"
        allowClear
        onChange={handleSearch}
        style={{ marginBottom: 20, width: 200 }}
      />
      <Table
        columns={columns}
        dataSource={filteredEmployees.map(employee => ({ ...employee, key: employee.id }))}
      />
      <Drawer
        title={`Goals of ${selectedEmployee ? selectedEmployee.fullName : ''}`}
        width={800}
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        {selectedEmployee && (
          <EmployeeGoalsView employeeId={selectedEmployee.id} />
        )}
      </Drawer>
    </div>
  );
};

export default ManagerView;
