import React, { useEffect, useState } from 'react';
import WeekRangeSelector from './WeekRangeSelector';
import TimesheetTable from './timesheetTable';
import { useNavigate } from "react-router-dom";
import { Spin, message } from 'antd';
import { useAuth } from '../../context/AuthContext';
import authHeader from '../../services/auth-header';
import moment from 'moment';

const TimesheetComponent = () => {
  const [selectedWeek, setSelectedWeek] = useState({});
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [employeeId, setEmployeeId] = useState(null);
  const navigate = useNavigate();

  const handleWeekSelect = (weekRange) => {
    setSelectedWeek(weekRange);
  };

  const handleApiError = (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
    } else {
      message.error('Failed to fetch data');
    }
  };

  const fetchEmployeeId = async (mail) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/getEmployeeId`, {
        method: 'POST',
        headers: {
          ...authHeader(),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userMail: mail })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch employee ID');
      }
      const data = await response.json();
      localStorage.setItem('employeeId', data.employeeId);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      if (user) {
        const storedEmployeeId = localStorage.getItem('employeeId');
        if (storedEmployeeId) {
          setEmployeeId(storedEmployeeId);
        } else {
          await fetchEmployeeId(user.email);
          const newEmployeeId = localStorage.getItem('employeeId');
          setEmployeeId(newEmployeeId);
        }
        setLoading(false);
      } else {
        navigate('/login'); // Redirect to login if user is not authenticated
      }
    };
    initializeData();
  }, [user, navigate]);

  if (loading) {
    return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  }

  return (
    <div>
      <WeekRangeSelector onWeekSelect={handleWeekSelect} />
      {selectedWeek && selectedWeek.length === 2 && (
        <h6
          style={{
            fontSize: "15px",
            fontWeight: "normal",
            marginBottom: "15px",
          }}
        >
          Selected Week Range: {moment(selectedWeek[0]).format("MM/DD/YYYY")} -{" "}
          {moment(selectedWeek[1]).format("MM/DD/YYYY")}
        </h6>
      )}
      <TimesheetTable employeeId={employeeId} weekRange={selectedWeek} />
    </div>
  );
};

export default TimesheetComponent;
