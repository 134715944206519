import React from 'react'
import TimesheetComponent from '../components/Timesheet/timesheet';


const Timesheet = () => {
  return (
    
    <div>
    <h1><TimesheetComponent /></h1>
  </div>
  );
};

export default Timesheet;