import React, { useState, useEffect } from 'react';
import { Button, DatePicker, Form, Input, Select, Radio, Upload, message, Row, Col, Divider} from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Avatar, Space } from 'antd';
import axios from 'axios';
import authHeader from '../../services/auth-header';

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const EmpProfile = ({employee}) => {
  const [selectedImage, setSelectedImage] = useState(null);
    const [formData, setFormData] = useState({...employee});

    useEffect(() => {
        setFormData({...employee});
      }, [employee]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleImageChange = (info) => {
      if (info.fileList.length > 0) {
          const file = info.fileList[0].originFileObj;
          setSelectedImage(file);
      } else {
          setSelectedImage(null);
      }
  };

  const handleSave = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/employees/${formData.id}`, formData,{
        headers: authHeader()
    })
      .then(response => {
        message.success('Saved successfully!');
      })
      .catch(error => {
        console.error('Error saving data:', error);
        message.error('Failed to save data');
      });
  };

  

    return (

        <div style={{ border: '1px solid #ccc', padding: '20px', margin: '20px auto', maxWidth: '800px' }}>

          <Form layout="vertical">
            <Row gutter={[16, 16]}>
            <Col xs={24} md={8}>
                <Form.Item label="Profile Photo">
                  <Upload name="logo" action="/upload.do" listType="picture" onChange={handleImageChange} showRemoveIcon={false} disabled>
                    <div style={{ width: '200px', height: '200px', border: '1px solid #1890ff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      {selectedImage ? (
                        <img src={URL.createObjectURL(selectedImage)} alt="profile" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                      ) : (
                        <Space direction="vertical" size={16}>
                          <Space wrap size={16}>
                            <Avatar shape="square" size={200} icon={<UserOutlined />} />
                          </Space>
                        </Space>
                      )}
                    </div>
                    <Button icon={<PlusOutlined />} style={{ marginTop: '10px' }} disabled>Upload Photo</Button>
                  </Upload>
                </Form.Item>
              </Col>


            <Col xs={24} md={24}>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <Form.Item label="Full Name">
                  <Input name="fullName" value={employee.fullName} onChange={handleChange} />
                </Form.Item>
              </Col> 

              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Form.Item label="First Name">
                  <Input name="firstName" value={formData["firstName"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Middle Name">
                  <Input name="middleName" value={formData["middleName"]}  onChange={handleChange} />
                </Form.Item>  
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Last Name">
                  <Input name="lastName" value={formData["lastName"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Form.Item label="Employee ID">
                  <Input name="employeeId" value={formData["employeeId"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Email">
                  <Input name="emailId" value={formData["emailId"]}   onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Personal Email">
                  <Input name="personalEmail" value={formData["personalEmail"]}   onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Designation">
                  <Input name="designation" value={formData["designation"]} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Location">
                  <Input name="branchId" value={formData["branchId"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Mobile no.">
                  <Input name="mobileNo" value={formData["mobileNo"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Emergency Conatct">
                  <Input name="emergencyContact" value={formData["emergencyContact"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Date Of Birth">
                  <DatePicker 
                    value={formData["dateOfBirth"] ? moment(formData["dateOfBirth"], 'YYYY-MM-DD') : null}
                    onChange={(date, dateString) => handleChange({ target: { name: "dateOfBirth", value: dateString }})}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
              <Form.Item label="Blood Group">
                  <Input name="bloodGroup" value={formData["bloodGroup"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="CTC">
                  <Input name="CTC" value={formData["CTC"]}  onChange={handleChange} />
                </Form.Item>

              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Nationality">
                  <Input name="nationality" value={formData["nationality"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Employee Type">
                  <Input name="type" value={formData["type"]}  onChange={handleChange} />
                </Form.Item>

              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Supervisor Email">
                  <Input name="supervisorEmail" value={formData["supervisorEmail"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>


              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Gender">
                  <Radio.Group name="gender" value={formData["gender"]} onChange={handleChange}>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Marital Status">
                  <Radio.Group name="maritalStatus" value={formData["maritalStatus"]} onChange={handleChange}>
                    <Radio value="Single">Single</Radio>
                    <Radio value="Married">Married</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              </Row>
              <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Current Address">
                  <Input.TextArea name="presentAddress" value={formData["presentAddress"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Permanent Address">
                  <Input.TextArea name="permanentAddress" value={formData["permanentAddress"]}  onChange={handleChange} />
                </Form.Item>
              </Col>
              </Row>
            </Col>
            </Row>


            <Row justify="start">
              <Col>
                  <Button type="primary"  onClick={handleSave} >Save</Button>
              </Col>
            </Row>

          </Form>
        </div>
      );
    };

    export default EmpProfile;
    