export default function authHeader() {
    // Directly retrieve the token as a string from localStorage
    const token = localStorage.getItem('jwtToken');
  
    if (token) {
      return { 'Authorization': `Bearer ${token}` }; // Use Bearer format
    } else {
      return {};
    }
  }
  