import React, { useEffect, useState } from "react";
import { Table, Spin, message, Space, Modal } from "antd";
import axios from "axios";
import moment from "moment";
import authHeader from "../../services/auth-header";
import { FaRegCommentDots } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const TimesheetComponent = ({ employeeId, weekRange }) => {
  const navigate = useNavigate();
  const [timesheetData, setTimesheetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [projects, setProjects] = useState([]);
  const [activities, setActivities] = useState([]);

  const fetchTimesheetData = async () => {
    if (weekRange.length !== 2) {
      return;
    }

    const weekStart = weekRange[0].format("YYYY-MM-DD");
    const weekEnd = weekRange[1].format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/timesheet?employeeId=${employeeId}&weekStart=${weekStart}&weekEnd=${weekEnd}`,
        {
          headers: authHeader(),
        }
      );

      const data = response.data;

      if (
        data.message === "Timesheet exists" &&
        Array.isArray(data.timesheets)
      ) {
        setTimesheetData(data.timesheets);
      } else if (data.message === "Timesheet not exists") {
        setTimesheetData([]);
      } else {
        message.error("Unexpected response format.");
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAllActivities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/project-tasks-list`,
        {
          headers: authHeader(),
        }
      );

      const data = response.data;

      const activitiesData = Object.entries(data).map(([id, name]) => ({
        id: parseInt(id),
        name: name,
      }));

      setActivities(activitiesData);
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/projectList`,
        {
          headers: authHeader(),
        }
      );

      const data = response.data;
      const projectsData = Object.keys(data).map((key) => ({
        id: parseInt(key),
        name: data[key],
      }));
      setProjects(projectsData);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (employeeId && weekRange.length === 2) {
      fetchTimesheetData();
      fetchProjects();
      fetchAllActivities();
    }
  }, [employeeId, weekRange]);

  const showModal = (data) => {
    const project = projects.find((p) => p.id === parseInt(data.projectID));
    const activity = activities.find((a) => a.id === parseInt(data.taskID));
    setModalData({
      ...data,
      projectName: project ? project.name : `Project ${data.projectID}`,
      activityName: activity ? activity.name : `Activity ${data.taskID}`,
    });
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  if (loading || projects.length === 0 || activities.length === 0) {
    return <Spin size="large" />;
  }

  const handleApiError = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const newToken = error.response.headers["x-new-token"];
        if (newToken) {
          localStorage.setItem("token", newToken);
          sessionStorage.setItem("token", newToken);

          // Retry the request with the new token
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${newToken}`;

          return axios(config);
        } else {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      } else {
        message.error("Failed to fetch data");
      }
    } else {
      // Handle network errors or other cases where there is no response
      message.error("An error occurred while communicating with the server");
    }
  };

  const getColumnTitle = (index) => {
    const date = moment(weekRange[0]).add(index, "days");
    const dayName = date.format("ddd");
    const dayNumber = date.format("DD");

    return (
      <div style={{ whiteSpace: "nowrap" }}>{`${dayName} - ${dayNumber}`}</div>
    );
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      width: 200,
    },
    {
      title: "Activity",
      dataIndex: "activityName",
      key: "activityName",
      width: 200,
    },
    ...Array.from({ length: 7 }, (_, i) => {
      const date = moment(weekRange[0]).add(i, "days").format("YYYY-MM-DD");

      return {
        title: getColumnTitle(i),
        dataIndex: date,
        key: date,
        width: 100,
        render: (text, record) => {
          const dayData =
            record.timesheets.find((ts) => ts.taskDate === date) || {};
          const status = dayData.status || "Not Submitted";
          const iconStyle = {
            fontSize: "16px",
            verticalAlign: "middle",
          };

          let statusIcon;
          if (status === "Submitted") {
            statusIcon = (
              <span
                style={{ ...iconStyle, color: "#008000" }}
                title="Submitted"
              >
                &#10003;
              </span>
            );
          } else if (status === "Approved") {
            statusIcon = (
              <span style={{ ...iconStyle, color: "#008000" }} title="Approved">
                &#10003;&#10003;
              </span>
            );
          } else if (status === "Rejected") {
            statusIcon = (
              <span style={{ ...iconStyle, color: "#FF0000" }} title="Rejected">
                X
              </span>
            );
          }

          return (
            <Space direction="horizontal" size="small">
              <div>{dayData.duration || ""}</div>
              {(dayData.duration ||
                dayData.comments ||
                dayData.managerComment) && (
                <FaRegCommentDots
                  style={{ cursor: "pointer" }}
                  onClick={() => showModal(dayData)}
                />
              )}
              {statusIcon}
            </Space>
          );
        },
      };
    }),
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: 100,
      render: (text, record) => {
        const totalDuration = record.timesheets.reduce((sum, ts) => {
          if (!ts.duration) return sum;
          const [hours, minutes] = ts.duration.split(":").map(Number);
          return sum + hours * 60 + minutes;
        }, 0);

        const hours = Math.floor(totalDuration / 60);
        const minutes = totalDuration % 60;
        return `${hours}:${minutes.toString().padStart(2, "0")}`;
      },
    },
  ];

  const aggregateTimesheetData = (timesheets) => {
    const aggregatedData = {};

    timesheets.forEach((ts) => {
      const key = `${ts.projectID}-${ts.taskID}`;
      if (!aggregatedData[key]) {
        const project = projects.find((p) => p.id === parseInt(ts.projectID));
        const activity = activities.find((a) => a.id === parseInt(ts.taskID));
        aggregatedData[key] = {
          projectName: project ? project.name : `Project ${ts.projectID}`,
          activityName: activity ? activity.name : `Activity ${ts.taskID}`,
          timesheets: [],
        };
      }
      aggregatedData[key].timesheets.push(ts);
    });

    return Object.values(aggregatedData);
  };

  const dataSource = aggregateTimesheetData(timesheetData).map(
    (data, index) => ({
      key: index,
      projectName: data.projectName,
      activityName: data.activityName,
      timesheets: data.timesheets,
    })
  );

  // Calculate the total sum for the "Total" column
  const totalSum = dataSource.reduce((total, record) => {
    return (
      total +
      record.timesheets.reduce((sum, ts) => {
        if (!ts.duration) return sum;
        const [hours, minutes] = ts.duration.split(":").map(Number);
        return sum + hours * 60 + minutes;
      }, 0)
    );
  }, 0);

  const totalHours = Math.floor(totalSum / 60);
  const totalMinutes = totalSum % 60;

  return (
    <>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell colSpan={columns.length - 1}>
              <b>Total week hours:</b>
            </Table.Summary.Cell>
            <Table.Summary.Cell>
              <b>
                {totalHours}:{totalMinutes.toString().padStart(2, "0")}
              </b>
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
      <Modal
        title="Timesheet Details"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          <strong>Project Name:</strong> {modalData?.projectName}
        </div>
        <div>
          <strong>Activity Name:</strong> {modalData?.activityName}
        </div>
        <div>
          <strong>Date:</strong> {modalData?.taskDate}
        </div>
        <div>
          <strong>Duration:</strong> {modalData?.duration || "N/A"}
        </div>
        <div>
          <strong>Comments:</strong> {modalData?.comments || "No comments"}
        </div>
        <div>
          <strong>Manager Comment:</strong>{" "}
          {modalData?.managerComment || "No comments"}
        </div>
      </Modal>
    </>
  );
};

export default TimesheetComponent;
