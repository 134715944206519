import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
// import HrInfo from "./addNewEmployee"
import EmpProfile from "./empProfiles";
import axios from "axios"; // Import axios for making HTTP requests
import authHeader from "../../services/auth-header";

const Employees = () => {
    const [showForm, setShowForm] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [showProfile, setShowProfile] = useState(false);
    const [employees, setEmployees] = useState([]);


    useEffect(() => {
        // Fetch data when component mounts
        fetchEmployees();
    }, []);

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/employees`,{
                headers: authHeader()
            }); // Replace "your-api-endpoint" with your actual API endpoint
            setEmployees(response.data); // Assuming API response is an array of employee objects
        } catch (error) {
            console.error("Error fetching employees:", error);
        }
    };

    const toggleForm = () => {
        setShowForm(!showForm);
        // setEditMode(false);
    };
    // const toggleFormEmp = () => {
    //   // setShowProfile(!showProfile);
    //   setSelectedEmployee(employee); 
    // };

    const handleProfileClick = (employee) => {
        setSelectedEmployee(employee); // Set the selected employee
        setShowProfile(!showProfile);
        // setEditMode(true);
    };





    return (
        <div style={{ border: '1px solid #ccc', padding: '16px' }}>

            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12}>
                    <h2 style={{ marginBottom: '16px', fontWeight: 'bold', color: 'blue' }}>Employees</h2>
                </Col>

            </Row>


            <Row gutter={[16, 16]}>

                <Col xs={24} sm={24} md={12}>
                    {employees.map(employee => (
                        <Row key={employee.id} gutter={[16, 16]} style={{ border: '1px solid #ccc', padding: '5px', marginTop: '10px', maxWidth: '100%', marginTop: '20px' }}>

                            <Col xs={24} md={16} lg={18} xl={20}>
                                <div >
                                    <label htmlFor="FullName" style={{ color: 'blue', fontWeight: 'bold', cursor: 'pointer' }} onClick={() => handleProfileClick(employee)} > {employee.fullName}</label><br />
                                    <label htmlFor="Designation" style={{ color: 'orange' }}>{employee.designation}</label><br />

                                </div>
                            </Col>
                        </Row>

                    ))}
                </Col>
                <Col xs={24} sm={24} md={12}>

                    {showProfile && <EmpProfile employee={selectedEmployee} />}
                </Col>
            </Row>
        </div>
    );
};


export default Employees;
