import React, { useState, useEffect } from 'react';
import { DatePicker, Select, Row, Col } from 'antd';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/en-gb';

dayjs.extend(isoWeek);
dayjs.extend(customParseFormat);
dayjs.locale('en-gb');

const { Option } = Select;

const WeekRangeSelector = ({ onWeekSelect }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs().startOf('isoWeek'));
  const [selectedWeek, setSelectedWeek] = useState(null);

  const handleWeekSelect = (value) => {
    setSelectedWeek(value);
    const [startStr, endStr] = value.split(' - ');
    const start = dayjs(startStr, 'DD/MM/YYYY').startOf('day');
    const end = dayjs(endStr, 'DD/MM/YYYY').endOf('day');
    onWeekSelect([start.toDate(), end.toDate()]);
    setSelectedDate(null); // Reset selected date when week range is selected
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (selectedDate) {
      const startOfWeek = selectedDate.startOf('isoWeek');
      const endOfWeek = startOfWeek.endOf('isoWeek');
      const value = `${startOfWeek.format('DD/MM/YYYY')} - ${endOfWeek.format('DD/MM/YYYY')}`;
      handleWeekSelect(value);
    }
  }, [selectedDate]);

  const generateWeekOptions = () => {
    const options = [];
    for (let i = 0; i <= 6; i++) {
      const startOfWeek = dayjs().startOf('isoWeek').subtract(i, 'weeks');
      const endOfWeek = startOfWeek.endOf('isoWeek');
      const weekLabel = `${startOfWeek.format('DD/MM/YYYY')} - ${endOfWeek.format('DD/MM/YYYY')}`;
      options.push(<Option key={i} value={weekLabel}>{weekLabel}</Option>);
    }
    return options;
  };

  return (
    <Row align="middle" style={{ marginBottom: '1rem' }}>
     
        <h3>Choose the Week:</h3>
     
      <Col xs={24} sm={12} md={8} lg={6}>
        <Select
          onChange={handleWeekSelect}
          value={selectedWeek}
          placeholder="Select a week"
          style={{ width: '70%' }}
        >
          {generateWeekOptions()}
        </Select>
        <DatePicker
          onChange={handleDateSelect}
          value={selectedDate}
          style={{ width: '30%' }}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
          format="DD/MM/YYYY"
        />
      </Col>
    </Row>
  );
};
 
export default WeekRangeSelector;