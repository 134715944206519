import React from 'react'
import EmployeeListComponent from '../components/HrComponent/EmployeeListComponent';

const AllTimesheet = () => {
  return (
    
    <div>
    <h1><EmployeeListComponent /></h1>
  </div>
  );
};

export default AllTimesheet;