import React, { useState } from 'react';
import { Row, Col, Tooltip, Dropdown, Menu, message, Modal, Input } from 'antd';
import { startOfWeek, isEqual } from 'date-fns';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const EmployeeTimesheet = ({ timesheets, onApprove, onReject }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [rejectionComment, setRejectionComment] = useState('');

  const showModal = (entries) => {
    setSelectedEntries(entries);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let messageText = '';
    const formattedDate = selectedEntries[0] ? new Date(selectedEntries[0].taskDate).toLocaleDateString() : '';
    const actionType = rejectionComment ? 'rejected' : 'approved';
    messageText = `${formattedDate ? `Timesheet for ${formattedDate}` : 'Timesheet'} ${actionType} successfully`;

    if (rejectionComment) {
      messageText += ` with comment: ${rejectionComment}`;
    }

    message.success(messageText);
    onReject(selectedEntries, rejectionComment);
    setIsModalVisible(false);
    setRejectionComment('');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setRejectionComment('');
  };

  const isSameWeek = (date1, date2) => {
    const startOfWeek1 = startOfWeek(date1);
    const startOfWeek2 = startOfWeek(date2);
    return isEqual(startOfWeek1, startOfWeek2);
  };

  const handleMenuClick = (e, entries, entry, date) => {
    if (e.key === 'approve') {
      onApprove([entry], false);
    } else if (e.key === 'reject') {
      showModal([entry]);
    } else if (e.key === 'approveDay') {
      const dayEntries = entries.filter(item => item.taskDate === date);
      onApprove(dayEntries, false); // Pass all entries for the day
    } else if (e.key === 'rejectDay') {
      const dayEntries = entries.filter(item => item.taskDate === date);
      showModal(dayEntries);
    } else if (e.key === 'approveWeek') {
      const weekEntries = entries.filter(item => item.taskDate && isSameWeek(new Date(item.taskDate), new Date(date)));
      onApprove(weekEntries, true); // Pass all entries for the week
    } else if (e.key === 'rejectWeek') {
      const weekEntries = entries.filter(item => item.taskDate && isSameWeek(new Date(item.taskDate), new Date(date)));
      showModal(weekEntries);
    }
  };

  const renderColumns = (dates, entries) => {
    return dates.map(date => {
      const entriesForDate = entries.filter(entry => entry.taskDate === date);

      const menu = (
        <Menu onClick={(e) => handleMenuClick(e, entries, entriesForDate[0], date)}>
          <Menu.Item key="approve">Approve</Menu.Item>
          <Menu.Item key="reject">Reject</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="approveDay">Approve Day</Menu.Item>
          <Menu.Item key="rejectDay">Reject Day</Menu.Item>
          <Menu.Divider />
          <Menu.Item key="approveWeek">Approve Week</Menu.Item>
          <Menu.Item key="rejectWeek">Reject Week</Menu.Item>
        </Menu>
      );

      return (
        <Col key={date} style={{ width: 150, textAlign: 'center', border: '1px solid #f0f0f0', padding: '8px' }}>
          <Row>
            {entriesForDate.map(entry => (
              <Col key={entry.id} style={{ width: 150, textAlign: 'center', padding: '8px' }}>
                <Tooltip title={entry.comments}>
                  <Dropdown overlay={menu} trigger={['contextMenu']}>
                    <p style={{ margin: 0, cursor: 'pointer' }}>{entry.duration}</p>
                  </Dropdown>
                </Tooltip>
              </Col>
            ))}
          </Row>
        </Col>
      );
    });
  };

  const renderRows = () => {
    return Object.values(timesheets).map((employee) => {
      const dates = Array.from(new Set(employee.entries.map(entry => entry.taskDate)));

      return (
        <div key={employee.employeeID}>
          <Row gutter={[16, 16]} align="middle" style={{ display: 'flex', marginBottom: '16px' }}>
            <Col span={24}>
              <Collapse accordion>
                <Panel header={employee.employeeName} key={employee.employeeID}>
                  <Row gutter={[16, 16]}>
                    {dates.map(date => (
                      <Col key={date} style={{ width: 150, textAlign: 'center', padding: '8px', backgroundColor: '#f5f5f5', border: '1px solid #e8e8e8' }}>
                        {new Date(date).toLocaleDateString()}
                      </Col>
                    ))}
                  </Row>
                  <Row gutter={[16, 16]}>
                    {renderColumns(dates, employee.entries)}
                  </Row>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </div>
      );
    });
  };

  const dates = Array.from(new Set(Object.values(timesheets).flatMap(employee => employee.entries.map(entry => entry.taskDate))));
  return (
    <div>
      <div style={{ overflowX: 'auto' }}>
        <div style={{ minWidth: 150 * (dates.length + 1), position: 'relative' }}>
          <Row gutter={[16, 16]} align="middle" style={{ display: 'flex', marginBottom: 10, fontWeight: 'bold', backgroundColor: '#001529', color: '#fff', borderBottom: '2px solid #e8e8e8' }}>
            <Col style={{ width: 150, textAlign: 'left', position: 'sticky', left: 0, background: '#001529', zIndex: 1, padding: '8px' }}>Employee Name</Col>
          </Row>
          {renderRows()}
        </div>
        <Modal title="Reject Timesheet" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Input.TextArea
            value={rejectionComment}
            onChange={(e) => setRejectionComment(e.target.value)}
            placeholder="Enter rejection comment"
            rows={4}
          />
        </Modal>
      </div>
      <div style={{ textAlign: 'center', padding: '10px 0', color: '#999', marginTop: '20px' }}>
        Right-click on an entry to approve or reject.
      </div>
    </div>
  );
};

export default EmployeeTimesheet;
