import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, DatePicker, Form, Input, Radio, Upload, message, Row, Col, Space, Avatar, Divider } from 'antd';
import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import authHeader from '../../services/auth-header';

const MyInfo = () => {
  const navigate = useNavigate();

  const [selectedImage, setSelectedImage] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const storedEmployeeId = localStorage.getItem('employeeId');

  const [editableFields] = useState({
    Branch_ID: false,
    Mobile_no: true,
    Emergency_Mobile_no: true,
    Date_Of_Birth: true,
    Nationality: true,
    Gender: true,
    Marital_Status: true,
    Current_address: true,
    Permanent_address: true,
    supervisorEmail:false,
  });

  const [formData, setFormData] = useState({
    fullName: "",
    firstName: "",
    middleName: "",
    lastName: "",
    employeeId: "",
    emailId: "",
    designation: "",
    branchId: "",
    mobileNo: "",
    emergencyContact: "",
    dateOfBirth: "",
    nationality: "",
    gender: "",
    maritalStatus: "",
    presentAddress: "",
    permanentAddress: "",
    supervisorEmail: "",
  });

  useEffect(() => {
    getEmployeeProfile();
  }, []);

  const handleApiError = (error) => {
    console.error('API Error:', error);

    if (error.response && error.response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/login');
    } else {
      message.error('Failed to fetch data');
    }
  };

  const getEmployeeProfile = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/employees/${storedEmployeeId}`, {
      headers: authHeader()
    })
      .then(response => {
        const fetchedData = response.data;
        setFormData({
          ...fetchedData,
          supervisorEmail: fetchedData.supervisorEmail || "",
        });
      })
      .catch(error => {
        handleApiError(error);
      });
  };

  const saveEmployeeProfile = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/employees/${storedEmployeeId}`, formData, {
      headers: authHeader()
    })
      .then(response => {
        message.success('Profile saved successfully!');
        setEditMode(false);
      })
      .catch(error => {
        handleApiError(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = (info) => {
    if (info.fileList.length > 0) {
      const file = info.fileList[0].originFileObj;
      setSelectedImage(file);
    } else {
      setSelectedImage(null);
    }
  };

  const handleSave = () => {
    saveEmployeeProfile();
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  return (
    <div style={{ border: '1px solid #ccc', padding: '20px', margin: '20px auto', maxWidth: '800px' }}>
      <Form layout="vertical">
        <Row gutter={[16, 16]}>
          <Col xs={24} md={8}>
            <Form.Item label="Profile Photo">
              <Upload name="logo" action="/upload.do" listType="picture" onChange={handleImageChange} showRemoveIcon={false} disabled>
                <div style={{ width: '200px', height: '200px', border: '1px solid #1890ff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {selectedImage ? (
                    <img src={URL.createObjectURL(selectedImage)} alt="profile" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
                  ) : (
                    <Space direction="vertical" size={16}>
                      <Space wrap size={16}>
                        <Avatar shape="square" size={200} icon={<UserOutlined />} />
                      </Space>
                    </Space>
                  )}
                </div>
                <Button icon={<PlusOutlined />} style={{ marginTop: '10px' }} disabled>Upload Photo</Button>
              </Upload>
            </Form.Item>
          </Col>

          <Col xs={24} md={16}>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <Form.Item label="Full Name">
                  <Input name="fullName" value={formData.fullName} disabled={!editMode || !editableFields.fullName} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={8}>
                <Form.Item label="First Name">
                  <Input name="firstName" value={formData.firstName} disabled={!editMode || !editableFields.firstName} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Middle Name">
                  <Input name="middleName" value={formData.middleName} disabled={!editMode || !editableFields.middleName} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={8}>
                <Form.Item label="Last Name">
                  <Input name="lastName" value={formData.lastName} disabled={!editMode || !editableFields.lastName} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Employee ID">
                  <Input name="employeeId" value={formData.employeeId} disabled={!editMode || !editableFields.employeeId} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Email">
                  <Input name="emailId" value={formData.emailId} disabled={!editMode || !editableFields.emailId} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Designation">
                  <Input name="designation" value={formData.designation} disabled={!editMode || !editableFields.designation} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Location">
                  <Input name="branchId" value={formData.branchId} disabled={!editMode || !editableFields.Branch_ID} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24}>
                <Form.Item label="Supervisor Email">
                  <Input 
                    name="supervisorEmail" 
                    value={formData.supervisorEmail} 
                    disabled={!editMode || !editableFields.supervisorEmail} 
                    onChange={handleChange} 
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider />
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Mobile No.">
                  <Input name="mobileNo" value={formData.mobileNo} disabled={!editMode} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Emergency Contact">
                  <Input name="emergencyContact" value={formData.emergencyContact} disabled={!editMode} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Date Of Birth">
                  <DatePicker
                    value={formData.dateOfBirth ? moment(formData.dateOfBirth, 'YYYY-MM-DD') : null}
                    disabled={!editMode}
                    onChange={(date, dateString) => handleChange({ target: { name: "dateOfBirth", value: dateString }})}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Nationality">
                  <Input name="nationality" value={formData.nationality} disabled={!editMode} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Gender">
                  <Radio.Group name="gender" value={formData.gender} disabled={!editMode} onChange={handleChange}>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Marital Status">
                  <Radio.Group name="maritalStatus" value={formData.maritalStatus} disabled={!editMode} onChange={handleChange}>
                    <Radio value="Single">Single</Radio>
                    <Radio value="Married">Married</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Form.Item label="Current address">
                  <Input.TextArea name="presentAddress" value={formData.presentAddress} disabled={!editMode} onChange={handleChange} />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <Form.Item label="Permanent address">
                  <Input.TextArea name="permanentAddress" value={formData.permanentAddress} disabled={!editMode} onChange={handleChange} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row justify="start">
          <Col>
            {!editMode ? (
              <Button type="primary" onClick={handleEditClick}>Edit</Button>
            ) : (
              <Button type="primary" onClick={handleSave}>Save</Button>
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default MyInfo;