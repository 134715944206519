import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Collapse, Spin, message } from 'antd';
import WeekRangeSelector from '../Timesheet/WeekRangeSelector';
import TimesheetComponent from './TimesheetComponent';
import { useAuth } from '../../context/AuthContext';
import authHeader from '../../services/auth-header';
import { useNavigate } from 'react-router-dom';

const { Panel } = Collapse;

const EmployeeListComponent = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  const fetchEmployees = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/employees`, {
        headers: authHeader()
      });
      setEmployees(response.data);
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  };
  const handleApiError = (error) => {
    if (error.response) {
        if (error.response.status === 401) {
            const newToken = error.response.headers['x-new-token'];
            if (newToken) {
                localStorage.setItem('token', newToken);
                sessionStorage.setItem('token', newToken);

                // Retry the request with the new token
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${newToken}`;

                return axios(config);
            } else {
                localStorage.clear();
                sessionStorage.clear();
                navigate('/login');
            }
        } else {
            message.error('Failed to fetch data');
        }
    } else {
        // Handle network errors or other cases where there is no response
        message.error('An error occurred while communicating with the server');
    }
};

  const handleWeekSelect = (weekRange) => {
    setSelectedWeek(weekRange);
  };

  useEffect(() => {
    if (user) {
      fetchEmployees();
    }
  }, [user]);

  if (loading) {
    return <Spin size="large" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} />;
  }

  return (
    <div>
      <WeekRangeSelector onWeekSelect={handleWeekSelect} />
      {selectedWeek.length === 2 && (
        <h6 style={{ fontSize: '15px', fontWeight: 'normal', marginBottom: '15px' }}>
          Selected Week Range: {selectedWeek[0]?.format('MM/DD/YYYY')} - {selectedWeek[1]?.format('MM/DD/YYYY')}
        </h6>
      )}
      <Collapse>
        {employees.map(employee => (
          <Panel header={employee.fullName} key={employee.id}>
            <TimesheetComponent employeeId={employee.id} weekRange={selectedWeek} />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default EmployeeListComponent;
