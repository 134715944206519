import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './components/Login/login';
import Dashboard from './pages/dashboard';
import EmployeeForm from './pages/registerForm';


const App = () => {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   const checkAuthentication = () => {
  //     const token = localStorage.getItem('jwtToken');
  //     const isAuthenticated = sessionStorage.getItem('isAuthenticated');
  //     if (!token || !isAuthenticated) {
  //       navigate('/login');
  //     }
  //   };

  //   checkAuthentication();
  // }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<EmployeeForm />} />
      <Route path="/dashboard/*" element={<Dashboard />} />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
};

export default App;
