import React, { useState} from 'react';
import loginlogo from '../../../src/GNAPI LOGO.png';

const Login = () => {
  const [error, setError] = useState(null); 

  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const scope = 'openid profile email User.Read';
  const responseType = 'code';
  const authorizationEndpoint = 'https://login.microsoftonline.com/7322cf37-1b8e-4c9a-8180-c9d95fb4ed15/oauth2/v2.0/authorize';

  const handleLogin = () => {

    try {
      const authUrl = `${authorizationEndpoint}?client_id=${clientId}&response_type=${responseType}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=${encodeURIComponent(scope)}`;
      window.location.replace(authUrl)
    } catch (err) {
      setError('An error occurred during login. Please try again.');
      console.error('Login error:', err);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '80vh',
        marginTop: '100px',
      }}
    >
      <div
        style={{
          border: '2px solid #e8e8e8',
          padding: '20px',
          borderRadius: '5px',
          width: '400px',
        }}
      >
        <img src={loginlogo} alt="Logo" style={{ width: '100%', marginBottom: '20px' }} />
       
          <div>
            <p style={{ textAlign: 'center' }}>Please sign in to continue</p>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                style={{
                  backgroundColor: '#0078d4',
                  color: '#fff',
                  border: 'none',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={handleLogin}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#005a9e')}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#0078d4')}
              >
                Login with Office 365
              </button>
            </div>
          </div>
        
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default Login;