import React, { useState, useEffect } from 'react';
import MyGoal from './myGoal';
import YourGoals from './YourGoals';
import { useAuth } from '../../context/AuthContext';

const GoalManagement = () => {
  const [employeeId, setEmployeeId] = useState(null);
  const [refreshGoals, setRefreshGoals] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const newEmployeeId = localStorage.getItem('employeeId');
    setEmployeeId(newEmployeeId);
  }, []);

  const handleGoalAdded = () => {
    // Trigger a re-fetch of goals
    setRefreshGoals(prev => !prev);
  };

  return (
    <div>
      <MyGoal employeeId={employeeId} onGoalAdded={handleGoalAdded} />
      <YourGoals employeeId={employeeId} refreshGoals={refreshGoals} />
    </div>
  );
};

export default GoalManagement;
