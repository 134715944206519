import React, { useState, useEffect } from "react";
import {
  Table,
  Select,
  Space,
  Input,
  Button,
  Modal,
  message,
  Upload,
  Row,
  Col,
  Card,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { FaRegCommentDots } from "react-icons/fa";
import {
  DeleteOutlined,
  PlusCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import authHeader from "../../services/auth-header";

const { Option } = Select;
const { Text } = Typography;

const TimesheetTable = ({ employeeId, weekRange }) => {
  const storedEmployeeId = localStorage.getItem("employeeId");
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [activities, setActivities] = useState([]);
  const [editingData, setEditingData] = useState([{}]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [saveResponse, setSaveResponse] = useState(null);
  const [weekSelected, setWeekSelected] = useState(false);
  const [projectSelected, setProjectSelected] = useState(false);
  const [activitySelected, setActivitySelected] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [hoverIndex, setHoverIndex] = useState(null);
  const [hoverType, setHoverType] = useState(null);
  const [timesheetStatus, setTimesheetStatus] = useState("Not Submitted");
  const [isEditable, setIsEditable] = useState(true);
  const [fetchedTimesheet, setFetchedTimesheet] = useState([{}]);

  useEffect(() => {
    setEditingData([{}]);
    fetchTimesheetData();
  }, [employeeId, weekRange]);

  useEffect(() => {
    if (editingData.length === 0) {
      setTimesheetStatus("Not Submitted");
      setIsEditable(true); // Enable editing when timesheet is not submitted
      return;
    }

    const dateKeys = Object.keys(editingData[0]).filter((key) =>
      /^\d{2}\/\d{2}\/\d{4}$/.test(key)
    );
    if (dateKeys.length === 0) {
      setTimesheetStatus("Not Submitted");
      setIsEditable(true); // Enable editing when timesheet is not submitted
      return;
    }

    const statuses = dateKeys.map((dateKey) => editingData[0][dateKey].status);

    if (statuses.every((status) => status === "Approved")) {
      setTimesheetStatus("Approved");
      setIsEditable(false); // Disable editing when timesheet is approved
    } else if (statuses.some((status) => status === "Rejected")) {
      setTimesheetStatus("Rejected");
      setIsEditable(true); // Enable editing when timesheet is rejected
    } else if (statuses.some((status) => status === "Submitted")) {
      setTimesheetStatus("Submitted");
      setIsEditable(false); // Disable editing when timesheet is submitted
    } else {
      setTimesheetStatus("Not Submitted");
      setIsEditable(true); // Enable editing when timesheet is not submitted
    }
  }, [editingData]);

  const handleUploadChange = ({ fileList }) => {
    setModalData((prevData) => ({
      ...prevData,
      attachment: fileList,
    }));
  };

  const fetchTimesheetData = async () => {
    // window.location.reload();
    setProjectSelected(false);
    setActivitySelected(false);
    await fetchProjects();
    await fetchAllActivities();
    const weekStart = moment(weekRange[0]).format("YYYY-MM-DD");
    const weekEnd = moment(weekRange[1]).format("YYYY-MM-DD");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/timesheet?employeeId=${employeeId}&weekStart=${weekStart}&weekEnd=${weekEnd}`,
        {
          headers: authHeader(),
        }
      );

      const data = response.data;

      if (Array.isArray(data.timesheets) && data.timesheets.length > 0) {
        const newDataList = [];

        data.timesheets.forEach((timesheet) => {
          const status = timesheet.status || "Not Submitted";
          const managerComment =
            status === "Rejected" ? timesheet.managerComment : null;
  
          const taskDate = moment(timesheet.taskDate).format("MM/DD/YYYY");
          const durationHours = moment.duration(timesheet.duration).asHours();
          const hours = Math.floor(durationHours).toString().padStart(2, "0");
          const minutes = Math.round((durationHours - hours) * 60)
            .toString()
            .padStart(2, "0");
  
          const formattedDuration = `${hours}:${minutes}`;
  
          // // Find the activity name using the activity ID
          // const activity = activities.find(a => a.id === timesheet.taskID);
          // const activityName = activity ? activity.name : timesheet.taskID;
  
          // Find the index of the existing entry for this project and activity
          const existingIndex = newDataList.findIndex(
            (item) =>
              item.projectName === timesheet.projectID &&
              item.activityName === timesheet.taskID
          );
  
          if (existingIndex !== -1) {
            // Update the existing entry with the new date's data
            newDataList[existingIndex][taskDate] = {
              duration: formattedDuration,
              comments: timesheet.comments,
              status: status, // Add the status
              managerComment: managerComment, // Add the manager's comment if rejected
            };
          } else {
            // Create a new entry
            const newData = {
              projectName: timesheet.projectID,
              activityName: timesheet.taskID,
              taskDate: taskDate,
              [taskDate]: {
                duration: formattedDuration,
                comments: timesheet.comments,
                status: status,
                managerComment: managerComment,
              },
              existsInDatabase: true,
            };
            newDataList.push(newData);
          }
        });
        // Set newDataList into editingData
        setEditingData(newDataList);
        setFetchedTimesheet(newDataList);
      } else {
        setEditingData([{}]);
      }
    } catch (error) {
      handleApiError(error);
    }
  };
  const validateWeekdayEntries = () => {
    let isValid = true;
    const warnings = {};
  
    editingData.forEach((item, index) => {
      const projectName = projects.find(p => p.id === item.projectName)?.name || 'Unknown Project';
      const activityName = activities.find(a => a.id === item.activityName)?.name || 'Unknown Activity';
      const key = `${projectName} - ${activityName}`;
  
      if (!item.projectName || !item.activityName) {
        warnings[key] = warnings[key] || [];
        warnings[key].push(`Row ${index + 1}: Please select both Project and Activity`);
        isValid = false;
        return; // Skip further validation for this row
      }
  
      for (let i = 0; i <= 6; i++) {
        const date = moment(weekRange[0]).add(i, "days").format("MM/DD/YYYY");
        const dayData = item[date] || {};
  
        if (dayData.duration && dayData.duration !== "00:00" && !dayData.comments) {
          warnings[key] = warnings[key] || [];
          warnings[key].push(date);
          isValid = false;
        }
      }
    });

    if (!isValid) {
      Modal.warning({
        title: "Incomplete Entries",
        content: (
          <div>
            <p>Please address the following issues:</p>
            <ul>
              {Object.keys(warnings).map((key, index) => (
                <li key={index}>
                  {key}: Missing comment for {warnings[key].join(", ")}
                </li>
              ))}
            </ul>
          </div>
        ),
      });
    }

    return isValid;
  };

  const handleApiError = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const newToken = error.response.headers["x-new-token"];
        if (newToken) {
          localStorage.setItem("token", newToken);
          sessionStorage.setItem("token", newToken);

          // Retry the request with the new token
          const config = error.config;
          config.headers["Authorization"] = `Bearer ${newToken}`;

          return axios(config);
        } else {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/login");
        }
      } else {
        message.error("Failed to fetch data");
      }
    } else {
      // Handle network errors or other cases where there is no response
      message.error("An error occurred while communicating with the server");
    }
  };

  const fetchActivities = async (projectId, index) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/project-task/${projectId}`,
        {
          headers: authHeader(),
        }
      );
  
      const data = response.data;
  
      const activitiesData = Object.entries(data).map(([id, name]) => ({
        id: parseInt(id),
        name: name,
      }));

      setActivities(activitiesData);

      if (index !== undefined && activitiesData.length > 0) {
        handleInputActivity(index, "activityName", activitiesData[0].id);
      }
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAllActivities = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/project-tasks-list`,
        {
          headers: authHeader(),
        }
      );

      const data = response.data;

      const activitiesData = Object.entries(data).map(([id, name]) => ({
        id: parseInt(id),
        name: name,
      }));

      setActivities(activitiesData);
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/projectList`,
        {
          headers: authHeader(),
        }
      );

      const data = response.data;
      const projectsData = Object.keys(data).map((key) => ({
        id: parseInt(key),
        name: data[key],
      }));
      setProjects(projectsData);
    } catch (error) {
      handleApiError(error);
    }
  };
  const onProjectChange = (value, index) => {
    setEditingData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...(newData[index] || {}),
        projectName: value,
        activityName: undefined, // Reset activityName when project changes
      };
      return newData;
    });
    setProjectSelected(!!value);
    fetchActivities(value, index);
  };

  const handleInputChange = (index, date, field, value) => {
    // if (projectSelected && activitySelected) {
    setEditingData((prevData) => {
      const newData = [...prevData];
      const status = newData[index][date]?.status || "Not Submitted"; // Get the existing status or default to 'Not Submitted'
      newData[index] = {
        ...(newData[index] || {}),
        taskDate: date,
        [date]: {
          ...(newData[index]?.[date] || {}),
          [field]: value,
          // comments: "", // Reset comments
          status: status, // Include the status
        },
      };
      return newData;
    });
    // }
  };

  const validateTime = (value) => {
    // Regular expression to match valid time format (HH:MM)
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeRegex.test(value);
  };

  const formatTime = (value) => {
    // Handle empty input
    if (!value) return "";

    // Remove any non-digit characters except colon
    const cleanValue = value.replace(/[^\d:]/g, '');

    // Split the input into hours and minutes
    let [hours, minutes] = cleanValue.split(':');

    // Convert to numbers
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes, 10) || 0;

    // Handle cases like "8" (interpret as 08:00)
    if (isNaN(minutes)) {
      minutes = 0;
    }

    // Format the time
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

    // Validate the formatted time
    if (!validateTime(formattedTime)) {
      message.warning(`Invalid time format: ${value}. Please use HH:MM format (00:00 to 23:59).`);
      return "00:00";
    }

    return formattedTime;
  };
  const handleTimeInputBlur = (index, date, value) => {
    const formattedValue = formatTime(value);

    setEditingData((prevData) => {
      const newData = [...prevData];
      const status = newData[index][date]?.status || "Not Submitted";
      newData[index] = {
        ...(newData[index] || {}),
        taskDate: date,
        [date]: {
          ...(newData[index]?.[date] || {}),
          duration: formattedValue,
          status: status,
        },
      };
      return newData;
    });
  };
  const handleCommentChange = (index, date, comment) => {
    // if (projectSelected && activitySelected) {
    setEditingData((prevData) => {
      const newData = [...prevData];
      const status = newData[index][date]?.status || "Not Submitted"; // Get the existing status or default to 'Not Submitted'
      newData[index] = {
        ...(newData[index] || {}),
        taskDate: date,
        [date]: {
          ...(newData[index]?.[date] || {}),
          comments: comment,
          status: status, // Include the status
        },
      };
      return newData;
    });
    setModalData((prevModalData) => ({ ...prevModalData, Comments: comment }));
    // }
  };
  const handleInputActivity = (index, field, value) => {
    const isActivityAlreadySelected = editingData.some(
      (item, idx) =>
        idx !== index &&
        item.projectName === editingData[index].projectName &&
        item.activityName === value
    );

    if (isActivityAlreadySelected) {
      // Show warning or handle the duplicate activity selection here
      message.warning("Activity already selected for this project.");
      return;
    }

    setActivitySelected(!!value); // Update activitySelected state
    setEditingData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...(newData[index] || {}),
        [field]: value,
      };
      return newData;
    });
  };
  const handleDeleteRow = async (index) => {
    const Start = moment(weekRange[0]);
    const End = moment(weekRange[1]);
    const weekStart = Start.format("YYYY-MM-DD");
    const weekEnd = End.format("YYYY-MM-DD");
    const projectId = editingData[index]?.projectName;
    const taskId = editingData[index]?.activityName;
    const entryExists = editingData[index]?.existsInDatabase;

    Modal.confirm({
      title: "Delete Entry",
      content: "Are you sure you want to delete this entry?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: async () => {
        // If confirmed, proceed with deletion
        if (entryExists) {
          try {
            await axios.delete(
              `${process.env.REACT_APP_API_URL}/timesheet/delete`,
              {
                headers: authHeader(), // Correct placement of headers
                data: {
                  employeeId,
                  projectId,
                  taskId,
                  weekStart,
                  weekEnd,
                },
              }
            );

            // Update editingData state by filtering out the deleted row
            const newData = editingData.filter((_, i) => i !== index);
            if (newData.length === 0) {
              // Add a default empty row if no rows are left after deletion
              newData.push({});
            }
            setEditingData(newData);
            message.success("Row deleted successfully");
            fetchTimesheetData();
          } catch (error) {
            console.error("Error deleting timesheet:", error);
            message.error("Error deleting timesheet");
            // handleApiError(error);
          }
        } else {
          const newData = editingData.filter((_, i) => i !== index);
          if (newData.length === 0) {
            // Add a default empty row if no rows are left after deletion
            newData.push({});
          }
          setEditingData(newData);
          message.success("Row deleted successfully");
        }
      },
    });
  };

  const handleSave = async () => {
    // Check if there are entries for Saturday or Sunday
    const isSatSunEntryPresent = editingData.some((item) =>
      Object.keys(item).some(
        (key) =>
          moment(key, "MM/DD/YYYY").isoWeekday() === 6 || // 6 represents Saturday
          moment(key, "MM/DD/YYYY").isoWeekday() === 7 // 7 represents Sunday
      )
    );

    if (isSatSunEntryPresent) {
      // Show a warning message before saving
      Modal.confirm({
        title: "Warning",
        content:
          " Are you sure you want to calculate time for Saturday and Sunday?",
        okText: "Yes",
        cancelText: "No",
        onOk: async () => {
          // Proceed with saving the timesheet
          await saveTimesheet();
          await fetchTimesheetData();
        },
      });
    } else {
      // No entries for Saturday or Sunday, proceed with saving directly
      await saveTimesheet();
    }
  };

  const saveTimesheet = async () => {
    try {
      const timesheets = [];
      let hasValidEntry = false;
      let hasProjectWarning = false;
      let hasActivityWarning = false;
      let hasDurationWarning = false;

      for (const item of editingData) {
        for (const key of Object.keys(item)) {
          // Check if the key is a date by attempting to parse it
          if (moment(key, "MM/DD/YYYY", true).isValid()) {
            // Check if the entry is not submitted or approved
            const status = item[key]?.status || "Not Submitted";
            if (status !== "Submitted" && status !== "Approved") {
              const timesheetEntry = {
                employeeID: employeeId,
                taskDate: moment(key, "MM/DD/YYYY").format("YYYY-MM-DD"),
                projectID: item.projectName,
                taskID: item.activityName,
                comments: item[key].comments || "",
                duration: item[key].duration,
              };

              // Check if the duration differs from the fetched data
              const fetchedItem = fetchedTimesheet.find(
                (f) =>
                  f.projectName === item.projectName &&
                  f.activityName === item.activityName
              );
              const durationChanged =
                fetchedItem &&
                fetchedItem[key]?.duration !== null &&
                fetchedItem[key]?.duration !== item[key]?.duration;

              // Check conditions for warnings
              if (item.projectName && item.activityName && item[key].duration) {
                timesheets.push(timesheetEntry);
                hasValidEntry = true;
              } else if (durationChanged) {
                timesheets.push(timesheetEntry);
                hasValidEntry = true;
              } else if (!item.projectName) {
                hasProjectWarning = true;
              } else if (!item.activityName) {
                hasActivityWarning = true;
              } else if (!item[key].duration) {
                hasDurationWarning = true;
              }
            }
          }
        }
      }

      // Display warnings
      if (!hasValidEntry) {
        if (hasProjectWarning && hasActivityWarning && hasDurationWarning) {
          message.warning(
            "Please select Project, Activity, and enter Duration."
          );
        } else if (hasProjectWarning && hasActivityWarning) {
          message.warning("Please select Project and Activity.");
        } else if (hasProjectWarning && hasDurationWarning) {
          message.warning("Please select Project and enter Duration.");
        } else if (hasActivityWarning && hasDurationWarning) {
          message.warning("Please select Activity and enter Duration.");
        } else if (hasProjectWarning) {
          message.warning("Please select Project.");
        } else if (hasActivityWarning) {
          message.warning("Please select Activity.");
        } else {
          message.warning("No valid entry to save.");
        }
        return;
      }

      // Call the API if there is at least one valid entry
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/timesheet/save`,
        { timesheets },
        { headers: authHeader() }
      );
      setSaveResponse(response.data);
      setIsSaveClicked(true);
      message.success("Timesheet saved successfully");
    } catch (error) {
      handleApiError(error);
    }
  };

  const handleSubmit = async () => {
    if (!validateWeekdayEntries()) {
      return;
    }

    try {
      if (!isSaveClicked) {
        // Display alert message prompting the user to save first
        message.warning("Save the data before submit");
        return; // Exit the function without proceeding to submit
      }
      // First, save the timesheet with the latest changes
      await saveTimesheet();

      // Check if saveResponse exists and has timesheets
      if (
        saveResponse &&
        saveResponse.timesheets &&
        saveResponse.timesheets.length > 0
      ) {
        // Extracting the saved timesheets from the saveResponse state
        const savedTimesheets = saveResponse.timesheets;
        const savedTimesheetIds = savedTimesheets.map(
          (timesheet) => timesheet.id
        );

        // Submit the newly saved timesheet IDs
        await axios.post(
          `${process.env.REACT_APP_API_URL}/timesheet/submit`,
          { timesheetIds: savedTimesheetIds, employeeId: storedEmployeeId },
          {
            headers: authHeader(),
          }
        );
        message.success("Timesheet submitted successfully");
      }

      // Fetch the updated timesheet data after saving/submitting
      fetchTimesheetData();
    } catch (error) {
      handleApiError(error);
    }
    setIsSaveClicked(false);
  };
  const addRow = () => {
    setEditingData((prevData) => [...prevData, {}]);
  };

  const handleMouseOver = (index, type) => {
    if (type === "addRow") {
      setHoverIndex(index);
      setHoverType(type);
    } else if (type === "delete") {
      setHoverIndex(index);
      setHoverType(type);
    }
  };

  const handleMouseOut = () => {
    setHoverIndex(null);
    setHoverType(null);
  };
  const getColumnTitle = (index) => {
    const date = moment(weekRange[0]).add(index, "days");
    const dayName = date.format("ddd");
    const dayNumber = date.format("DD");
    const formattedDate = date.format("MM/DD/YYYY");

    let dayStatus = "Not Submitted";
    for (let i = 0; i < editingData.length; i++) {
      const dayData = editingData[i]?.[formattedDate] || {};
      if (dayData.status && dayData.status !== "Not Submitted") {
        dayStatus = dayData.status;
        break;
      }
    }

    const iconStyles = {
      fontSize: "12px", // smaller size
      marginLeft: "4px",
      verticalAlign: "middle",
    };

    let statusIcon;
    if (dayStatus === "Submitted") {
      statusIcon = (
        <span style={{ ...iconStyles, color: "#008000" }} title="Submitted">
          &#10003;
        </span>
      );
    } else if (dayStatus === "Approved") {
      statusIcon = (
        <span style={{ ...iconStyles, color: "#008000" }} title="Approved">
          &#10003;&#10003;
        </span>
      );
    } else if (dayStatus === "Rejected") {
      statusIcon = (
        <span style={{ ...iconStyles, color: "#FF0000" }} title="Rejected">
          X
        </span>
      );
    } else if (dayStatus === "Saved") {
      statusIcon = (
        <span style={{ ...iconStyles, color: "#FFA500" }} title="Saved">
          &#10003;
        </span>
      ); // Orange tick
    }

    return (
      <div style={{ whiteSpace: "nowrap" }}>
        {`${dayName} - ${dayNumber}`}
        {statusIcon}
      </div>
    );
  };

  const columns = [
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      // width: 200, // Set a fixed width
      render: (text, record, index) => {
        const statuses = Array.from({ length: 7 }, (_, i) => {
          const date = moment(weekRange[0]).add(i, "days").format("MM/DD/YYYY");
          const dayData = editingData[index]?.[date] || {};
          return dayData.status || "Not Submitted";
        });

        const disableProject =
          statuses.includes("Submitted") || statuses.includes("Approved");

        return (
          <Select
            showSearch
            placeholder="Select a project"
            optionFilterProp="children" // Use 'children' to filter options based on their text
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value) => onProjectChange(value, index)}
            onFocus={fetchProjects}
            value={editingData[index]?.projectName || undefined}
            style={{ width: "100%", minWidth: 200 }}
            disabled={disableProject}
          >
            {projects.map((project) => (
              <Option key={project.id} value={project.id}>
                {project.name}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: "Activity",
      dataIndex: "activityName",
      key: "activityName",
      // width: 200, // Set a fixed width
      render: (text, record, index) => {
        const statuses = Array.from({ length: 7 }, (_, i) => {
          const date = moment(weekRange[0]).add(i, "days").format("MM/DD/YYYY");
          const dayData = editingData[index]?.[date] || {};
          return dayData.status || "Not Submitted";
        });

        const disableActivity =
          statuses.includes("Submitted") || statuses.includes("Approved");

        return (
          <Select
            showSearch
            placeholder="Select an activity"
            optionFilterProp="children" // Use 'children' to filter options based on their text
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: "100%", minWidth: 200 }}
            onChange={(value) =>
              handleInputActivity(index, "activityName", value)
            }
            value={editingData[index]?.activityName || undefined}
            disabled={disableActivity || !editingData[index]?.projectName}
          >
            {activities &&
              activities.map((activity) => (
                <Option key={activity.id} value={activity.id}>
                  {activity.name}
                </Option>
              ))}
          </Select>
        );
      },
    },
    ...Array.from({ length: 7 }, (_, i) => {
      const date = moment(weekRange[0]).add(i, "days");
      const isWeekday = date.day() >= 0 && date.day() <= 6; // Monday is 1, Friday is 5
      const formattedDate = date.format("MM/DD/YYYY");
    
      return {
        title: getColumnTitle(i),
        dataIndex: `date-${i}`,
        key: `date-${i}`,
        render: (text, record, index) => {
          const dayData = editingData[index]?.[formattedDate] || {};
          const dayStatus = dayData.status || "Not Submitted";
    
          return (
            <div>
              <Space direction="horizontal" size="small" style={{ width: "100%" }}>
              <Input
                  value={dayData.duration || ""}
                  onChange={(e) =>
                    handleInputChange(
                      index,
                      formattedDate,
                      "duration",
                      e.target.value
                    )
                  }
                  onBlur={(e) =>
                    handleTimeInputBlur(index, formattedDate, e.target.value)
                  }
                  placeholder="HH:MM"
                  style={{
                    width: 60,
                    textAlign: "center",
                  }}
                  disabled={dayStatus === "Submitted" || dayStatus === "Approved"}
                />
                <FaRegCommentDots
                  onClick={() => {
                    setModalData({
                      index: index,
                      projectName: projects.find(
                        (p) => p.id === editingData[index]?.projectName
                      )?.name,
                      activityName: activities.find(
                        (a) => a.id === editingData[index]?.activityName
                      )?.name,
                      date: formattedDate,
                      dayStatus: dayStatus,
                      Comments: dayData.comments || "",
                      managerComment: dayData.managerComment || "",
                      duration: dayData.duration || "",
                    });
                    setIsModalVisible(true);
                  }}
                  style={{
                    cursor:
                      dayStatus !== "Submitted" && dayStatus !== "Approved"
                        ? "pointer"
                        : "not-allowed",
                    color: dayData.duration && dayData.duration !== "00:00" && !dayData.comments ? "red" : undefined,
                  }}
                  disabled={dayStatus === "Submitted" || dayStatus === "Approved"}
                />
              </Space>
            </div>
          );
        },
        onHeaderCell: () => ({
          style: { textAlign: "center" },
        }),
        summary: (pageData) => {
          const totalDuration = pageData.reduce((total, row) => {
            const duration = (row[formattedDate] && row[formattedDate].duration) || "00:00";
            const [hours, minutes] = duration.split(":").map(Number);
            return total + (hours * 60 + minutes);
          }, 0);
          const hours = Math.floor(totalDuration / 60);
          const minutes = totalDuration % 60;
          const dayTotalFormatted = `${hours}:${minutes
            .toString()
            .padStart(2, "0")}`;
    
          return (
            <div style={{ textAlign: "center", fontWeight: "bold" }}>
              {dayTotalFormatted}
            </div>
          );
        },
      };
    }),    
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text, record, index) => {
        const totalDuration = Object.values(editingData[index] || {})
          .filter(
            (value) =>
              typeof value === "object" && value !== null && "duration" in value
          )
          .reduce((sum, { duration }) => {
            if (!duration) return sum;
            const [hours, minutes] = duration.split(":").map(Number);
            return sum + hours * 60 + minutes;
          }, 0);

        const hours = Math.floor(totalDuration / 60);
        const minutes = totalDuration % 60;
        return `${hours}:${minutes.toString().padStart(2, "0")}`;
      },
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      summary: (pageData) => {
        const grandTotalDuration = pageData.reduce((total, row) => {
          return (
            total +
            Object.values(row)
              .filter(
                (value) =>
                  typeof value === "object" &&
                  value !== null &&
                  "duration" in value
              )
              .reduce((sum, { duration }) => {
                if (!duration) return sum;
                const [hours, minutes] = duration.split(":").map(Number);
                return sum + hours * 60 + minutes;
              }, 0)
          );
        }, 0);

        const hours = Math.floor(grandTotalDuration / 60);
        const minutes = grandTotalDuration % 60;
        const grandTotalFormatted = `${hours}:${minutes
          .toString()
          .padStart(2, "0")}`;

        return (
          <div style={{ textAlign: "center", fontWeight: "bold" }}>
            {grandTotalFormatted}
          </div>
        );
      },
    },
    {
      // title: 'Actions',
      dataIndex: "actions",
      key: "actions",
      render: (text, record, index) => {
        const statuses = Array.from({ length: 7 }, (_, i) => {
          const date = moment(weekRange[0]).add(i, "days").format("MM/DD/YYYY");
          const dayData = editingData[index]?.[date] || {};
          return dayData.status || "Not Submitted";
        });

        const disableDelete =
          statuses.includes("Submitted") || statuses.includes("Approved");

        return (
          <Space>
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() =>  handleDeleteRow(index)}
              disabled={disableDelete}
              danger
            />
            <Button
              type="text"
              icon={<PlusCircleOutlined />}
              onClick={addRow}
              style={{ color: "green" }}
            />
          </Space>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        title="Comment Details"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              if (
                modalData.duration &&
                modalData.duration !== "00:00" &&
                !modalData.Comments.trim()
              ) {
                message.error("Comment is required for this day");
                return;
              }
              setIsModalVisible(false);
            }}
          >
            OK
          </Button>,
        ]}
      >
        {modalData && (
          <div>
            <p>Project Name: {modalData.projectName}</p>
            <p>Activity Name: {modalData.activityName}</p>
            <p>Date: {modalData.date}</p>
            <Input.TextArea
              rows={4}
              value={modalData.Comments}
              placeholder={
                modalData.duration &&
                modalData.duration !== "00:00" &&
                !modalData.Comments.trim()
                  ? "Comment is required for this day"
                  : "Enter your comment"
              }
              onChange={(e) =>
                handleCommentChange(
                  modalData.index,
                  modalData.date,
                  e.target.value
                )
              }
              disabled={
                modalData.dayStatus === "Submitted" ||
                modalData.dayStatus === "Approved"
              }
              style={{
                borderColor:
                  modalData.duration &&
                  modalData.duration !== "00:00" &&
                  !modalData.Comments.trim()
                    ? "red"
                    : undefined,
              }}
            />
            <div style={{ marginTop: "10px" }}>
              <span style={{ marginRight: "10px", fontWeight: "bold" }}>
                Attachments:
              </span>
              <Upload
                fileList={modalData.attachment || []}
                onChange={handleUploadChange}
                beforeUpload={() => false} // Prevent automatic upload
              >
                <Button
                  icon={<UploadOutlined />}
                  disabled // for now disabling this field
                  // disabled={modalData.dayStatus === 'Submitted' || modalData.dayStatus === 'Approved'}
                >
                  Upload
                </Button>
              </Upload>
            </div>
            {modalData.managerComment && (
              <div style={{ marginTop: "10px", color: "red" }}>
                <p>Manager Comment: {modalData.managerComment}</p>
              </div>
            )}
          </div>
        )}
      </Modal>

      <Card style={{ width: "100%" }}>
        <Table
          columns={columns}
          dataSource={editingData}
          rowKey={(record, index) => index}
          pagination={false}
          scroll={{ x: "max-content" }}
          style={{ width: "100%" }}
          summary={() => (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}>
                  <div style={{ textAlign: "right", fontWeight: "bold" }}>
                    Total:
                  </div>
                </Table.Summary.Cell>
                {columns.slice(2, -2).map((col, index) => (
                  <Table.Summary.Cell key={index} index={index + 2}>
                    {col.summary && col.summary(editingData)}
                  </Table.Summary.Cell>
                ))}
                <Table.Summary.Cell index={9}>
                  {columns[columns.length - 2].summary &&
                    columns[columns.length - 2].summary(editingData)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} />
              </Table.Summary.Row>
            </Table.Summary>
          )}
        />
        <Row gutter={[16, 16]} style={{ marginTop: "1rem" }}>
          <Col xs={24} sm={12}>
            <Text strong>Status: </Text>
            <Text
              style={{
                color:
                  timesheetStatus === "Approved"
                    ? "green"
                    : timesheetStatus === "Rejected"
                    ? "red"
                    : "orange",
                fontStyle: "italic",
              }}
            >
              {timesheetStatus}
            </Text>
          </Col>
          <Col xs={24} sm={12} style={{ textAlign: "right" }}>
            <Space>
              <Button
                type="primary"
                onClick={handleSave}
                style={{ background: "green", borderColor: "green" }}
              >
                Save
              </Button>
              <Button
                type="primary"
                onClick={handleSubmit}
                style={{
                  background: isSaveClicked ? "green" : "gray", // Change background color when disabled
                  borderColor: isSaveClicked ? "green" : "gray", // Change border color when disabled
                  color: isSaveClicked ? "white" : "lightgray", // Ensure text is visible when disabled
                  cursor: isSaveClicked ? "pointer" : "not-allowed", // Change cursor to show it's disabled
                }}
                disabled={!isSaveClicked && editingData == null}
              >
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default TimesheetTable;
