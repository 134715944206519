import React, { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate, useLocation } from "react-router-dom";
import { IoTimeOutline } from "react-icons/io5";
import MyInfo from "./myinfo";
import Timesheet from "./timesheet";
import TimesheetReport from "../components/Timesheet/timesheetReport";
import AllTimesheet from "./allTimesheet";
import Goal from "./goal";
import Manager from "./managerView";
import EmployeeGoalsView from "../components/MyGoal/EmployeeGoalsView";
import logo from "../../src/Gnapi.png";
import {
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  BellOutlined,
  PoweroffOutlined,
  UserAddOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import {
  Layout,
  Menu,
  Spin,
  Dropdown,
  Button,
  Row,
  Col,
  Typography,
} from "antd";
import { useAuth } from "../context/AuthContext";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";
import Employees from "../components/EmployeesList/employeeList";

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, setUser, setUserStatus } = useAuth();

  // Extract token from query string
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  useEffect(() => {
    const tokenFromURL = queryParams.get("token");
    if (tokenFromURL && !localStorage.getItem("jwtToken")) {
      localStorage.setItem("jwtToken", tokenFromURL);
      // Set a flag in session storage
      sessionStorage.setItem("isAuthenticated", "true");
      setUserStatus("authenticated");
    }

    if (tokenFromURL) {
      fetchUserInfo(tokenFromURL);
    } else {
      setLoading(false);
    }
  }, [user, token]);

  const fetchUserInfo = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/user-info`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const userData = response.data;

      // Extract the role from userData
      const { user_name, email, role, user_id, employeeId } = userData;
      // Update the user state and localStorage with the role
      const updatedUser = { user_name, email, role, user_id, employeeId };
      localStorage.setItem("user", JSON.stringify(updatedUser));
      setUser(updatedUser);
    } catch (error) {
      console.error("Error fetching user information:", error);
      if (error.response && error.response.status === 500) {
        console.error("Server error:", error.response.data);
      } else if (error.message === "jwt malformed") {
        console.error("JWT malformed error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    confirmAlert({
      title: "Confirm Logout",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              localStorage.clear();
              sessionStorage.clear();
              document.cookie.split(";").forEach((c) => {
                document.cookie = c
                  .replace(/^ +/, "")
                  .replace(
                    /=.*/,
                    "=;expires=" + new Date().toUTCString() + ";path=/"
                  );
              });
              setUserStatus("unauthenticated");

              const tenant = process.env.REACT_APP_TENENT_ID;
              const clientId = process.env.REACT_APP_CLIENT_ID;
              const postLogoutRedirectUri = process.env.REACT_APP_URL;

              const logoutUrl = `https://login.microsoftonline.com/${tenant}/oauth2/v2.0/logout?client_id=${clientId}&post_logout_redirect_uri=${postLogoutRedirectUri}`;

              // Redirect the user to the logout URL
              window.location.replace(logoutUrl);
            } catch (error) {
              console.error("Error during logout:", error);
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleCloseApp = () => {
    window.close(); // This will only work for browser windows opened via JavaScript
  };

  if (loading) {
    return (
      <Spin
        size="large"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      />
    );
  }
  function getItem(label, key, icon, to, subItems) {
    return {
      key,
      icon,
      label,
      to,
      subItems,
    };
  }

  const menuItems = [
    getItem(
      <Link to="/dashboard/timesheet">Home</Link>,
      "1",
      <DesktopOutlined />
    ),
    getItem(
      <Link to="/dashboard/myinfo">MyProfile</Link>,
      "2",
      <UserOutlined />
    ),
    (user?.role === "hr" || user?.role === "admin") &&
      getItem(
        <Link to="/dashboard/employeeList">AllEmployee</Link>,
        "3",
        <UsergroupAddOutlined />
      ),
    getItem(
      <Link to="/dashboard/timesheet">Timesheet</Link>,
      "4",
      <IoTimeOutline />
    ),
    (user?.role === "manager" || user?.role === "admin") &&
      getItem(
        <Link to="/dashboard/timesheet-report">Timesheet Action</Link>,
        "5",
        <TeamOutlined />
      ),
    (user?.role === "hr" || user?.role === "manager") &&
      getItem(
        <Link to="/dashboard/allTimesheet">All Timesheets</Link>,
        "6",
        <IoTimeOutline />
      ),
    getItem(
      <Link to="/dashboard/mygoal">MyGoal</Link>,
      "7",
      <UserAddOutlined />
    ),
    (user?.role === "manager" || user?.role === "admin") &&
      getItem(
        <Link to="/dashboard/managerView">Employee Goals </Link>,
        "8",
        <UserAddOutlined />
      ),
  ].filter(Boolean);

  const userMenu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          padding: 0,
          background: "white",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <img
            src={logo}
            alt="Logo"
            style={{
              height: "100px",
              width: "200px",
              marginTop: "20px",
              marginLeft: "20px",
            }}
          />
        </div>
        <Row gutter={16} align="middle">
          <Col xs={24} sm={24}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <BellOutlined
                style={{
                  fontSize: "20px",
                  marginTop: "2px",
                  marginRight: "4px",
                }}
              />
              <Dropdown overlay={userMenu} trigger={["click"]}>
                <Text
                  strong
                  style={{
                    color: "black",
                    marginTop: "10px",
                    margin: "16px",
                    cursor: "pointer",
                  }}
                >
                  Welcome, {user ? user.user_name : "Guest"}!
                </Text>
              </Dropdown>
              <Button
                icon={<PoweroffOutlined />}
                onClick={handleCloseApp}
                style={{ marginLeft: "3px" }}
              />
            </div>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
          <Menu theme="dark" defaultSelectedKeys={["1"]} mode="inline">
            {menuItems.map((item) => (
              <Menu.Item key={item.key} icon={item.icon}>
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
        <Layout>
          <Content style={{ margin: "0 16px" }}>
            <Routes>
              <Route
                path="/"
                element={<Navigate replace to="/dashboard/timesheet" />}
              />
              <Route path="/myinfo" element={<MyInfo />} />
              {(user?.role === "hr" || user?.role === "admin") && (
                <Route path="/employeeList" element={<Employees />} />
              )}
              <Route path="/timesheet" element={<Timesheet />} />
              {(user?.role === "manager" || user?.role === "admin") && (
                <Route path="/timesheet-report" element={<TimesheetReport />} />
              )}
              {(user?.role === "hr" || user?.role === "manager") && (
                <Route path="/allTimesheet" element={<AllTimesheet />} />
              )}
              <Route path="/mygoal" element={<Goal />} />
              {(user?.role === "manager" || user?.role === "admin") && (
                <Route path="/managerview" element={<Manager />} />
              )}
              <Route
                path="/employee-goals/:employeeId"
                element={<EmployeeGoalsView />}
              />
            </Routes>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            {new Date().getFullYear()} Created by Gnapitech
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Dashboard;
