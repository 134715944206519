import React from 'react';
import GoalManagement from '../components/MyGoal/GoalManagement';

const Goal = () => {
  return (
    <div>
      <GoalManagement />
    </div>
  );
}

export default Goal;
