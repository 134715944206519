import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import EmployeeTimesheet from './EmployeeTimesheet';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import authHeader from '../../services/auth-header';
import axios from 'axios';

const TimesheetReport = () => {
  const [timesheets, setTimesheets] = useState({});
  const { user } = useAuth();
  const manager_id = user.user_id;
  const navigate = useNavigate();
 

  const handleApiError = (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        const newToken = error.response.headers['x-new-token'];
        if (newToken) {
          localStorage.setItem('token', newToken);
          sessionStorage.setItem('token', newToken);
  
          // Retry the request with the new token
          const config = error.config;
          config.headers['Authorization'] = `Bearer ${newToken}`;
  
          return axios(config);
        } else {
          localStorage.clear();
          sessionStorage.clear();
          navigate('/login');
        }
      } else {
        message.error('Failed to fetch data');
      }
    } else {
      // Handle network errors or other cases where there is no response
      message.error('An error occurred while communicating with the server');
    }
  };
  

  const fetchSubmittedTimesheets = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/submitted-timesheet/${manager_id}`, {
        headers: authHeader()
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      const groupedTimesheets = data.reduce((acc, entry) => {
        if (!acc[entry.employeeID]) {
          acc[entry.employeeID] = { employeeID: entry.employeeID, employeeName: entry.employeeName, entries: [] };
        }
        acc[entry.employeeID].entries.push(entry);
        return acc;
      }, {});
      setTimesheets(groupedTimesheets);
    } catch (error) {
      console.error('Error fetching timesheets:', error);
      handleApiError(error);
    }
  };

  useEffect(() => {
    if(user && user.user_id){
      fetchSubmittedTimesheets();
    }
  }, []);

  const handleApprove = async (entries) => {
    try {
        // Extract timesheet IDs from the entries
        const timesheetIds = entries.map(entry => entry.id).filter(id => id != null);
        
        
        // Proceed with the approval process if valid IDs are found
        if (timesheetIds.length > 0) {
            await fetch(`${process.env.REACT_APP_API_URL}/approve/timesheet`, {
                method: 'PUT',
                headers: {
                    ...authHeader(),
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ timesheetIds })
            });
            message.success(`Timesheet(s) approved successfully`);
            fetchSubmittedTimesheets(); // Refresh timesheets after approval
        } else {
            message.error('No valid timesheet IDs found');
        }
    } catch (error) {
        console.error('Error approving timesheets:', error);
        message.error('Error approving timesheets.');
        handleApiError(error);
    }
  };
  
  const handleReject = async (entries, rejectionComment) => {
    try {
      const requests = entries.flatMap(entry => {
        if (typeof entry === 'object' && entry.id) {
          return fetch(`${process.env.REACT_APP_API_URL}/timesheets/reject/${entry.id}`, {
            method: 'PUT',
            headers: {
              ...authHeader(),
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ rejectionComment })
          });
        } else {
          return [];
        }
      });
  
      await Promise.all(requests);
      fetchSubmittedTimesheets();
    } catch (error) {
      console.error('Error rejecting timesheets:', error);
      message.error('Error rejecting timesheets.');
      handleApiError(error);
    }
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    background: '#f5f5f5',
    position: 'sticky',
    top: 0,
    zIndex: 1,
  };

  const entryStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    borderBottom: '1px solid #e0e0e0',
  };

  const entryItemStyle = {
    flex: 1,
    padding: '0 10px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const containerStyle = {
    padding: '20px',
    overflowX: 'auto',
  };

  return (
    <div style={{ padding: '20px', overflowX: 'auto' }}>
      <h1>Timesheet Action</h1>
      <div style={{ overflowX: 'auto' }}>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}> {/* Set max height and overflow for scrolling */}
          <EmployeeTimesheet
            timesheets={timesheets}
            onApprove={handleApprove}
            onReject={handleReject}
          />
        </div>
      </div>
    </div>
  );

};

export default TimesheetReport;
