// export default MyGoal;
import React, { useState } from 'react';
import { Button, DatePicker, Input, Space, Select, Form, Row, Col, Modal } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import authHeader from '../../services/auth-header';
import moment from 'moment';
 
const { Option } = Select;
 
const MyGoal = ({ employeeId, onGoalAdded }) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [form] = Form.useForm();
 
  const handleAddClick = () => {
    if (isFormOpen) {
      Modal.confirm({
        title: 'Unsaved Goal',
        content: 'Do you want to Save the previous goal?',
        onOk() {
          form.submit();
        },
        onCancel() {
          form.resetFields();
          setIsFormOpen(true);
        },
      });
    } else {
      setIsFormOpen(true);
    }
  };
 
  const handleRemoveClick = () => {
    setIsFormOpen(false);
    form.resetFields();
  };
 
  const handleSubmit = (values, status) => {
    // Trim the URL value
    const trimmedUrl = values.url ? values.url.trim() : '';
 
    const goalData = {
      employeeID: employeeId,
      name: values.name,
      description: values.description,
      status: status,
      targetDate: values.targetDate,
      goalCategory: values.goalCategory,
      url: trimmedUrl  // Use the trimmed URL
    };
 
    axios.post(`${process.env.REACT_APP_API_URL}/goal`, goalData, {
      headers: authHeader()
    })
      .then(response => {
        onGoalAdded(); // Notify the parent component that a goal was added
        setIsFormOpen(false);
        form.resetFields();
      })
      .catch(error => {
        console.error('Error submitting goal:', error);
      });
  };
 
  const handleSave = () => {
    form.validateFields().then(values => {
      handleSubmit(values, 'Draft');
    });
  };
 
  const handleSubmitGoal = () => {
    form.validateFields().then(values => {
      handleSubmit(values, 'Submitted');
    });
  };
 
  return (
    <div style={{ padding: 20 }}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={handleAddClick}
        style={{ marginBottom: 20 }}
      >
        Add My Goal
      </Button>
 
      <Space direction="vertical" style={{ marginTop: 20, width: '100%' }}>
        {isFormOpen && (
          <Form form={form} layout="vertical">
            <div style={{ border: '1px solid #d9d9d9', padding: 20, borderRadius: 4, marginBottom: 20 }}>
              <Row gutter={16}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="name"
                    label="Goal Name"
                    rules={[{ required: true, message: 'Please input the goal name!' }]}
                  >
                    <Input placeholder="Goal Name" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name="goalCategory"
                    label="Select Category"
                    rules={[{ required: true, message: 'Please select a category!' }]}
                  >
                    <Select placeholder="Select Category">
                      <Option value="Expertise">Expertise</Option>
                      <Option value="Ownership">Ownership</Option>
                      <Option value="Leadership">Leadership</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="description"
                    label="Description"
                    rules={[{ required: true, message: 'Please input the description!' }]}
                  >
                    <Input.TextArea placeholder="Description" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="url"
                    label="URL"
                    rules={[
                      { required: false, message: 'Please input the URL!' },
                      { type: 'url', message: 'Please enter a valid URL!' }
                    ]}
                  >
                    <Input placeholder="URL" />
                  </Form.Item>
 
                </Col>
              </Row>
              <Row gutter={16}>
                <Col xs={24} sm={8}>
                  <Form.Item
                    name="targetDate"
                    label="Target Date"
                    rules={[{ required: true, message: 'Please select a target date!' }]}
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      disabledDate={(current) => {
                        return current && current < moment().startOf('day');
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>
                  <Button onClick={handleSave}>
                    Save
                  </Button>
                  <Button type="primary" onClick={handleSubmitGoal}>
                    Submit
                  </Button>
                  <Button
                    type="dashed"
                    icon={<MinusCircleOutlined />}
                    onClick={handleRemoveClick}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        )}
      </Space>
    </div>
  );
};
 
export default MyGoal;
 